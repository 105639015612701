export const queryKeys = {
    analyticsLeads: 'analyticsLeads',
    billingInfo: 'billingInfo',
    billingStatus: 'billingStatus',
    calendarIntegrations: 'calendarIntegrations',
    currentPlan: 'currentPlan',
    conversations: 'conversations',
    facebookIntegration: 'facebookIntegration',
    googleRedirectUrl: 'googleRedirectUrl',
    leads: 'leads',
    leadIngestionMethods: 'leadIngestionMethods',
    leadLabels: 'leadLabels',
    leadRoutingRules: 'leadRoutingRules',
    leadSources: 'leadSources',
    loggedInUser: 'loggedInUser',
    loggedInUserWithIngestEmailUsage: 'loggedInUserWithIngestEmailUsage',
    messages: 'messages',
    notifications: 'notifications',
    onboardingConfig: 'onboardingConfig',
    onboardingInvitation: 'onboardingInvitation',
    onboardingInvites: 'onboardingInvites',
    outlookRedirectUrl: 'outlookRedirectUrl',
    playbooks: 'playbooks',
    teamPlaybooks: 'teamPlaybooks',
    paymentMethods: 'paymentMethods',
    payments: 'payments',
    playbooksV2: 'playbooksV2',
    playbookTextTemplateVariables: 'playbookTextTemplateVariables',
    properties: 'properties',
    propertyLabels: 'propertyLabels',
    showings: 'showings',
    smartReplies: 'smartReplies',
    stripeClientSecret: 'stripeClientSecret',
    teamSettings: 'teamSettings',
    userSettings: 'userSettings',
    leadAttachments: 'leadAttachments',
};
