
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { Flex, FlexColumn } from 'src/components/flex';
import Responsive from 'src/components/responsive/Responsive';
import DesktopNavigationSidebar from 'src/components/layout/landlord/DesktopNavigationSidebar';
import FacebookIntegrationAlert from 'src/components/layout/landlord/FacebookIntegrationAlert';
import MaintenanceWindowAlert from 'src/components/layout/landlord/MaintenanceWindowAlert';
import HelpSidebar from 'src/components/layout/landlord/help-sidebar/HelpSidebar';
import LandlordLayoutProvider from 'src/components/layout/landlord/LandlordLayoutProvider';
import MobileAppBar from 'src/components/layout/landlord/MobileAppBar';
import MobileNavigationDrawer from 'src/components/layout/landlord/MobileNavigationDrawer';
import UnmatchedListingAlert from 'src/components/layout/landlord/UnmatchedListingAlert';
import InactivePropertiesModal from 'src/components/inactive-properties-modal/InactivePropertiesModal';
import SentryErrorBoundary from 'src/components/sentry-error-boundary';

import 'src/components/layout/landlord/LandlordLayout.css';

type Props = {
    isLoading?: boolean;
    children?: React.ReactNode;
}

export default function LandlordLayout({ isLoading, children }: Props) {
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <LandlordLayoutProvider>
            <FlexColumn minHeight="100vh" rowGap={0} columnGap={0}>
                <Responsive mobile>
                    <div>
                        <MobileAppBar openNavigationDrawer={handleDrawerOpen} />
                    </div>
                </Responsive>

                <Flex
                    flex={1}
                    minHeight="100%"
                    bgcolor="var(--background-color-beige)"
                    columnGap={0}
                    overflow="hidden"
                >
                    <DesktopNavigationSidebar onNavigationChange={handleDrawerClose} />

                    <SentryErrorBoundary>
                        <FlexColumn position="relative" width="100%">
                            {isLoading && (
                                <Box
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    width="100%"
                                >
                                    <LinearProgress />
                                </Box>
                            )}
                            {!isLoading && (
                                <>
                                    <UnmatchedListingAlert />
                                    <FacebookIntegrationAlert />
                                    <MaintenanceWindowAlert />
                                    {children}
                                </>
                            )}
                        </FlexColumn>
                    </SentryErrorBoundary>
                </Flex>

                {isDrawerOpen && (
                    <Responsive mobile>
                        <div>
                            <MobileNavigationDrawer closeNavigationDrawer={handleDrawerClose} />
                        </div>
                    </Responsive>
                )}
            </FlexColumn>

            {!isLoading && (
                <>
                    <HelpSidebar />
                    <InactivePropertiesModal />
                </>
            )}
        </LandlordLayoutProvider>
    );
}
