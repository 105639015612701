import React, { useMemo, useState } from 'react';
import { MergeIcon } from 'lucide-react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveIcon from '@mui/icons-material/UnarchiveOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';
import PeopleIcon from '@mui/icons-material/PeopleOutlineRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';

import { Flex } from 'src/components/flex';
import { type ILead, useUpdateLeadsStatuses } from 'src/services/api';
import { useAuthenticatedState } from 'src/authenticated-state/context';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import { useLandlordLayoutContext } from 'src/components/layout/landlord/LandlordLayoutProvider';
import CreateOrUpdateLeadModal from 'src/components/create-or-update-lead-modal';
import BulkChangeLeadStageModal from 'src/components/bulk-change-lead-stage-modal';
import BulkChangeLeadOwnerModal from 'src/components/bulk-change-lead-owner-modal';
import MergeLeadsModal from 'src/pages/landlord/leads/components/merge-leads-modal';

export default function LeadsToolbar() {
    const { loggedInUser, leadsByUuid } = useAuthenticatedState();

    const {
        mutateAsync: updateLeadsStatuses,
        isLoading: isUpdatingLeadsStatuses,
    } = useUpdateLeadsStatuses();

    const { sidebarWidth } = useLandlordLayoutContext();

    const {
        selectedLeadUuids,
        setSelectedLeadUuids,
    } = useLeadsPageContext();

    const [isEditingLead, setEditingLead] = useState(false);
    const [isChangingOwner, setChangingOwner] = useState(false);
    const [isMergingLeads, setIsMergingLeads] = useState(false);
    const [isChangingStage, setChangingStage] = useState(false);


    const areAllConversationsArchived = selectedLeadUuids.every((
        leadUuid
    ) => leadsByUuid.get(leadUuid)?.is_archived);
    const areAllConversationsUnarchived = selectedLeadUuids.every((
        leadUuid
    ) => !leadsByUuid.get(leadUuid)?.is_archived);

    const selectedLeads = useMemo(() => {
        const _selectedLeads: ILead[] = [];
        selectedLeadUuids.forEach((leadUuid) => {
            const lead = leadsByUuid.get(leadUuid);
            if (lead) {
                _selectedLeads.push(lead);
            }
        });

        return _selectedLeads;
    }, [selectedLeadUuids, leadsByUuid]);

    if (selectedLeadUuids.length === 0) {
        return null;
    }

    const updateArchivedStatus = async (isArchived: boolean) => {
        await updateLeadsStatuses(selectedLeadUuids.map((leadUuid) => ({
            uuid: leadUuid,
            is_archived: isArchived,
        })));

        setSelectedLeadUuids([]);
    };

    const cancel = () => {
        setSelectedLeadUuids([]);
    };

    const numberOfTeamMembers = loggedInUser?.leasing_team_members?.length || 0;
    const numberOfOtherTeamMembers = numberOfTeamMembers - 1;

    const canMergeLeads = selectedLeadUuids.length > 1 && selectedLeads.every((lead) => lead.owner === loggedInUser.id);

    return (
        <Flex
            className="desktopOnly"
            position="fixed"
            bottom={16}
            left={sidebarWidth}
            width={`calc(100vw - ${sidebarWidth}px)`}
            justifyContent="center"
        >
            <Flex component={Paper} py={0.5} px={1} alignItems="center" bgcolor="common.white" border="1px solid var(--border-color)">
                {selectedLeadUuids.length > 0 && (
                    <Button
                        onClick={() => setChangingStage(true)}
                        startIcon={<LeaderboardOutlinedIcon />}
                        data-testid="change-stage-button"
                    >
                        Change Stage
                    </Button>
                )}
                {isChangingStage && (
                    <BulkChangeLeadStageModal
                        open={isChangingStage}
                        onClose={() => setChangingStage(false)}
                        onSuccess={() => setSelectedLeadUuids([])}
                        leads={selectedLeads}
                    />
                )}

                {selectedLeadUuids.length === 1 && (
                    <>
                        <Button
                            onClick={() => setEditingLead(true)}
                            startIcon={<PersonIcon />}
                            data-testid="edit-lead-button"
                        >
                            Edit Lead
                        </Button>

                        {isEditingLead && (
                            <CreateOrUpdateLeadModal
                                lead={leadsByUuid.get(selectedLeadUuids[0])}
                                onClose={() => setEditingLead(false)}
                                onSave={() => {
                                    setEditingLead(false);
                                    setSelectedLeadUuids([]);
                                }}
                            />
                        )}
                    </>
                )}

                {numberOfOtherTeamMembers > 0 && (
                    <Button
                        onClick={() => setChangingOwner(true)}
                        startIcon={<PeopleIcon />}
                        data-testid="change-owner-button"
                    >
                        Change Owner
                    </Button>
                )}
                <BulkChangeLeadOwnerModal
                    open={isChangingOwner}
                    onClose={() => setChangingOwner(false)}
                    onSuccess={() => setSelectedLeadUuids([])}
                    leads={selectedLeads}
                />

                {canMergeLeads && (
                    <Button
                        onClick={() => setIsMergingLeads(true)}
                        startIcon={<MergeIcon size={20} />}
                    >
                        Merge Leads
                    </Button>
                )}
                <MergeLeadsModal
                    open={isMergingLeads}
                    onClose={() => setIsMergingLeads(false)}
                    onSuccess={() => {
                        setSelectedLeadUuids([]);
                        setIsMergingLeads(false);
                    }}
                />

                {areAllConversationsUnarchived && (
                    <LoadingButton
                        onClick={() => updateArchivedStatus(true)}
                        startIcon={<ArchiveIcon />}
                        loading={isUpdatingLeadsStatuses}
                        data-testid="archive-conversation-button"
                    >
                        Archive
                    </LoadingButton>
                )}

                {areAllConversationsArchived && (
                    <LoadingButton
                        onClick={() => updateArchivedStatus(false)}
                        startIcon={<UnarchiveIcon />}
                        loading={isUpdatingLeadsStatuses}
                        data-testid="unarchive-conversation-button"
                    >
                        Unarchive
                    </LoadingButton>
                )}

                <Divider orientation="vertical" />

                <IconButton
                    onClick={cancel}
                    size="small"
                    sx={{ borderRadius: 1 }}
                    data-testid="cancel-button"
                >
                    <CloseIcon />
                </IconButton>
            </Flex>
        </Flex>
    );
}
