import React from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Link from '@mui/material/Link';

import type { IMessageAttachment } from 'src/services/api';
import ExpandableItem from 'src/components/lead-sidebar/LeadSidebarExpandableItem';
import { FlexColumn, Flex } from 'src/components/flex';
import convertFileSize from 'src/utils/convert-file-size';

import styles from 'src/components/lead-sidebar/LeadSidebarMessageAttachments.module.css';

export default function LeadSidebarMessageAttachments ({ attachments }: { attachments: IMessageAttachment[] }){
    if (!attachments || attachments.length < 1) {
        return null;
    }

    return (
        <ExpandableItem title="Files">
            <FlexColumn>
                {attachments.map((attachment) => (
                    <Box
                        className={styles.attachment}
                        key={attachment.id}
                    >
                        <Flex justifyContent="space-between" width="100%">
                            <Flex>
                                <Flex alignItems="center">
                                    <Avatar sx={{ bgcolor: '#10B981' }}>
                                        <InsertDriveFileIcon />
                                    </Avatar>
                                </Flex>
                                <FlexColumn gap={0}>
                                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                        {attachment.original_filename}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#ADADAD'}}>
                                        {dayjs(attachment.inserted_at).format('MMM. D, H:mma')} &#x2022; {convertFileSize(attachment.file_size)} {attachment.file_size >= 1024 ? 'KB' : 'bytes'}
                                    </Typography>
                                </FlexColumn>
                            </Flex>

                            <Flex alignItems="center" justifyContent="flex-end">
                                <Link href={attachment.download_url} download target="_blank">
                                    <IconButton>
                                        <FileDownloadOutlinedIcon sx={{ color: '#ADADAD' }} />
                                    </IconButton>
                                </Link>
                            </Flex>
                        </Flex>
                    </Box>
                ))}
            </FlexColumn>
        </ExpandableItem>
    );
}