import { useQuery } from 'react-query';

import type { IProperty } from 'src/services/api/properties/types';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';

type IGetPropertiesOptions = {
    disabled?: boolean;
}

export type IGetPropertiesResponse = {
    properties: Array<IProperty>;
}

export const useProperties = (options?: IGetPropertiesOptions) => {
    return useQuery(
        [queryKeys.properties],
        async () => {
            const res = await apiClient(GET_PROPERTIES_PATH).json<IGetPropertiesResponse>();
            return res.properties;
        }, {
            enabled: !options?.disabled,
        }
    );
};
