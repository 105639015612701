import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';

import type { IConversation } from 'src/api/landlord-messages-api';
import { Auth } from 'src/services/api';
import { useAuthenticatedState } from 'src/authenticated-state/context';
import { useUpdateConversations } from 'src/services/service-workers/poll-conversations/use-update-conversations';

const SERVICE_WORKER_VERSION = 5;

export const usePollConversationsServiceWorker = () => {
    const { loggedInUser, conversations, isPaywallEnabled } = useAuthenticatedState();

    const init = useRef(false);
    const serviceWorkersRegistations = useRef<ServiceWorkerRegistration[]>([]);

    const updateConversations = useUpdateConversations();

    useEffect(() => {
        if (!loggedInUser.is_onboarding_complete) {
            return;
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(`/service-worker--poll-conversations-v${SERVICE_WORKER_VERSION}.js`)
                .then(registration => {
                    console.debug(`Service worker registered (version ${SERVICE_WORKER_VERSION}). Setting up event listeners...`);
                    serviceWorkersRegistations.current.push(registration);

                    if (isPaywallEnabled) {
                        registration.active?.postMessage({
                            type: 'STOP_POLLING',
                        });
                        return;
                    }

                    navigator.serviceWorker.addEventListener('message', (event) => {
                        if (event.data?.type === 'CONVERSATIONS_UPDATE') {
                            // Skip the first event, which is the initial state of the conversations.
                            if (!init.current) {
                                init.current = true;
                                return;
                            }

                            const data = event.data.payload as {
                                conversations: IConversation[];
                            };

                            if (!data.conversations) {
                                return;
                            }

                            const updatedConversations = data.conversations;
                            updateConversations(updatedConversations);
                        }

                        if (event.data?.type === 'REQUEST_ACCESS_TOKEN') {
                            if (isPaywallEnabled) {
                                console.debug('Paywall is enabled. Skipping service worker polling');
                                return;
                            }

                            registration.active?.postMessage({
                                type: 'UPDATE_ACCESS_TOKEN',
                                payload: {
                                    accessToken: Auth.accessToken,
                                }
                            });
                        }
                    });

                    return registration.update() as unknown as Promise<ServiceWorkerRegistration>;
                })
                .then(registration => {
                    console.debug('Service worker ready');

                    if (isPaywallEnabled) {
                        console.debug('Paywall is enabled. Skipping service worker polling');
                        return;
                    }

                    registration?.active?.postMessage({
                        type: 'START_POLLING',
                        payload: {
                            backendUrl: `${import.meta.env['VITE_APP_API_SERVER']}/api/landlord/conversations`,
                            accessToken: Auth.accessToken,
                            landlordUserId: Auth.loggedInUser?.id,
                            leasingTeamId: Auth.loggedInUser?.leasing_team_id,
                            updatedAt: dayjs().toISOString().slice(0, -1)
                        }
                    });
                })
                .catch(error => {
                    console.error('Service worker registration failed:', error);
                });
        }
    }, [conversations, updateConversations, isPaywallEnabled]);

    useEffect(() => {
        const stopPolling = () => {
            serviceWorkersRegistations.current.forEach((registration) => {
                registration.active?.postMessage({
                    type: 'STOP_POLLING'
                });
            });
        };

        document.addEventListener('reffie_logout', stopPolling);

        return () => document.removeEventListener('reffie_logout', stopPolling);
    }, []);
};
