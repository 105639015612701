import React from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { useAuthenticatedState } from 'src/authenticated-state/context';
import { Flex, FlexColumn } from 'src/components/flex';
import Responsive from 'src/components/responsive';
import ButtonLink from 'src/components/button-link';
import { isUnmatchedListingNotification } from 'src/services/api/notifications';
import pluralize from 'pluralize';

export default function UnmatchedListingAlert() {
    const { pathname } = useLocation();

    const { notifications } = useAuthenticatedState();

    if (
        pathname === '/landlord/inner/unmatched-listings' ||
        pathname.startsWith('/landlord/inner/messages/from-lead') ||
        (pathname.startsWith('/landlord/inner/playbooks') && pathname !== '/landlord/inner/playbooks')
    ) {
        return null;
    }

    const unmatchedListings = notifications
        .filter(isUnmatchedListingNotification)
        .filter((notification) => !notification.is_read_by_user);
    const numUnmatchedListings = unmatchedListings.length;
    if (numUnmatchedListings === 0) {
        return null;
    }

    return (
        <Responsive desktop>
            <Alert
                severity="error"
                color="error"
                sx={{
                    '& .MuiAlert-message': { width: '100%' },
                    '& .MuiAlert-icon': { paddingTop: '10px' },
                }}
            >
                <Flex width="100%" justifyContent="space-between" alignItems="center">
                    <FlexColumn>
                        <Typography fontWeight="bold" color="error">
                            Unmatched Listings
                        </Typography>
                        <Typography variant="body2" color="error">
                            {`${numUnmatchedListings} ${pluralize('listings', numUnmatchedListings)} are not matched to a property.`}
                        </Typography>
                    </FlexColumn>

                    <ButtonLink variant="outlined" color="error" href="/landlord/inner/unmatched-listings">
                        {`View ${pluralize('listing', numUnmatchedListings)}`}
                    </ButtonLink>
                </Flex>
            </Alert>
        </Responsive>
    );
}