const sourceMap: { [key: string]: string } = {
    'zillowrentalnetwork': 'Zillow Rental Network',
    'zillowrentals': 'Zillow Rentals',
    'zillow': 'Zillow Brands',
    'hotpads': 'Zillow Brands',
    'trulia': 'Zillow Brands',
    'zillowbrands': 'Zillow Brands',
    'zillowcom': 'Zillow Brands',
    'apartmentlist': 'Apartment List',
    'apartmentlistcom': 'Apartment List',
    'apartmentscom': 'Apartments.com Brands',
    'forrent': 'Apartments.com Brands',
    'apartmentfinder': 'Apartments.com Brands',
    'apartamentos': 'Apartments.com Brands',
    'apartmenthomeliving': 'Apartments.com Brands',
    'forrentuniversity': 'Apartments.com Brands',
    'after': 'Apartments.com Brands',
    'corporatehousing': 'Apartments.com Brands',
    'westsiderentals': 'Apartments.com Brands',
    'apartmentscombrands': 'Apartments.com Brands',
    'apartmentguidecom': 'Apartment Guide',
    'craigslist': 'Craigslist',
    'rentpath': 'RentPath',
    'realtorcom': 'Realtor.com',
    'redfin': 'Redfin',
    'website': 'Website',
    'otherwebsite': 'Website',
    'propertywebsite': 'Website',
    'streeteasy': 'StreetEasy',
    'zumper': 'Zumper',
    'rentlinx': 'RentLinx',
    'rentcafe': 'RentCafe',
    'affordablehousing': 'Affordable Housing',
    'fbmessenger': 'Facebook',
    'facebook': 'Facebook',
    'facebookmarketplace': 'Facebook',
    'tenantturner': 'Tenant Turner',
    'reffieinboundsms': 'Reffie Inbound SMS',
    'priorresident': 'Prior Resident',
    'appfolio': 'AppFolio',
    'showmojo': 'ShowMojo',
    'dwellsy': 'Dwellsy',
    'driveby': 'Driveby',
    'umovefree': 'UMoveFree',
    'turbotenant': 'TurboTenant',
    'collegestudentapartments': 'College Student Apartments',
    'inboundemail': 'Inbound Email',
    'propertyreferral': 'Property Referral',
    'roomsurf': 'Roomsurf',
    'abodo': 'Abodo',
    'lovely': 'Lovely',
    'promove': 'Promove',
    'synthetic': 'Synthetic',
    'locator': 'Locator',
    'rentler': 'Rentler',
    'uloop': 'ULoop',
    'google': 'Google',
    'googleads': 'Google',
    'housingforwardassistance': 'Housing Forward Assistance',
    'leaseterm': 'Lease Term',
    'offsite': 'Off Site Marketing',
    'padmapper': 'PadMapper',
    'walkins': 'Walk-ins',
    'reffiedatabase': 'Reffie Database',
    'migratedlead': 'Migrated Lead',
    'currentresidenttransfer': 'Current Resident Transfer',
    'wordofmouth': 'Word of Mouth',
    'rently': 'Rently',
    'rentcom': 'Rent.com',
};

const formatSource = (source: string) => {
    const match = sourceMap[source];
    if (match) { return match; }

    const fallback = source.charAt(0).toUpperCase() + source.slice(1);
    return fallback;
};

export const normalizeSource = (source: string) => {
    const normalizedSource = source.match(/[a-zA-Z]/g)?.join('').toLowerCase() || '';
    return formatSource(normalizedSource);
};
