import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { EConversationCategory } from 'src/pages/landlord/conversations/context/types';

import styles from 'src/components/layout/landlord/NavigationLink.module.css';

type Props = {
    text?: string;
    icon: React.ReactNode;
    link?: string;
    onClick: () => void;
};

export default function NavigationLink({
    text,
    icon,
    link,
    onClick,
}: Props) {
    const navigate = useNavigate();
    const location = useLocation();

    const isSelected = !!link && location.pathname.includes(link);
    const bgcolor = isSelected ? 'primary.50' : 'invalid';
    const color = isSelected ? 'primary.main' : 'invalid';

    const handleClick = () => {
        onClick();

        if (link) {
            navigate(link);
        }
    };

    const handleScrollClick = (sectionId: string) => {
        onClick();
        navigate('#' + sectionId);
    };

    const isConversationView = location.pathname.includes('/landlord/inner/messages') &&
        !location.pathname.includes('from-lead');

    return (
        <>
            <ListItem key={text} disablePadding data-testid={`${text}-link`}>
                <ListItemButton className={styles.button} onClick={handleClick} sx={{ bgcolor }}>
                    <ListItemIcon sx={{ color, minWidth: 0, marginRight: 1 }}>{icon}</ListItemIcon>
                    {!!text && <ListItemText secondary={text} sx={{ color }} />}
                </ListItemButton>
            </ListItem>

            {(text === 'Conversations' && isConversationView) && (
                <Box className={styles.conversationHeadings} data-testid="conversations-links-container">
                    <div
                        onClick={() => handleScrollClick(EConversationCategory.Unread)}
                        data-testid="unread-conversations-link"
                    >
                        Unread
                    </div>
                    <div
                        onClick={() => handleScrollClick(EConversationCategory.Active)}
                        data-testid="active-conversations-link"
                    >
                        Active
                    </div>
                    <Box
                        onClick={() => handleScrollClick(EConversationCategory.Ghosted)}
                        data-testid="ghosted-conversations-link"
                        sx={{
                            '& .MuiSvgIcon-root': { mb: '-3px' },
                            '&:hover .MuiSvgIcon-root': { fill: 'var(--color-primary-light)' },
                        }}
                    >
                        Ghosted
                    </Box>
                    <div
                        onClick={() => handleScrollClick(EConversationCategory.Stale)}
                        data-testid="stale-conversations-link"
                    >
                        Stale
                    </div>
                </Box>
            )}
        </>
    );
}