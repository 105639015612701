import { type IConversation } from 'src/api/landlord-messages-api';
import { type IUser } from 'src/api/users-api';
import { type UserSettings, type ILead } from 'src/services/api';
import { isNotificationApiSupported } from 'src/utils/browser-api';

export const sendNewMessageNotification = (
    userSettings: UserSettings | undefined,
    loggedInUser: IUser,
    conversation: IConversation,
    lead: ILead | undefined
) => {
    if (!isNotificationApiSupported()) { return; }
    if (!window.Notification || Notification.permission !== 'granted') { return; }
    if (userSettings?.notifications.new_message === 'off') { return; }
    if (userSettings?.notifications.new_message === 'owner_only' && loggedInUser.id !== lead?.owner) { return; }

    // tag is needed to not receive duplicate notifications
    const tag = conversation.lead_uuid + conversation.latest_message_contents;

    if (lead?.name) {
        new Notification(`New messages from ${lead.name}`, {
            tag,
            body: conversation.latest_message_contents
        });
    } else {
        new Notification('New messages from a lead', {
            tag,
            body: conversation.latest_message_contents
        });
    }
};
