import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ILead } from 'src/services/api/leads/types';
import { useNotifications } from 'src/notifications';
import { apiClient } from 'src/services/api/client';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';
import { getApiErrorMessage } from 'src/services/api/utils';
import { queryKeys } from 'src/services/api/constants';

type IMergeLeadsResponse = {
    lead: ILead;
};

type IMergeLeadsRequest = {
    primary_lead_uuid: string;
    secondary_lead_uuid: string;
}

export const useMergeLeads = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        ILead,
        IStandardErrorResponse,
        IMergeLeadsRequest
    >(async (payload) => {
        const path = `${GET_LEADS_PATH}/merge`;
        const res = await apiClient(path, {
            method: 'post',
            json: payload,
        }).json<IMergeLeadsResponse>();
        return res.lead;
    }, {
        onSuccess: (data, variables) => {
            // Update the list of leads in the cache
            queryClient.setQueryData<ILead[]>(queryKeys.leads, (oldData) => {
                if (!oldData) {
                    return [];
                }

                return oldData
                    .map((lead) => {
                        if (lead.uuid === variables.primary_lead_uuid) {
                            return data;
                        }

                        return lead;
                    })
                    .filter((lead) => lead.uuid !== variables.secondary_lead_uuid);
            });

            // Update the primary lead in the cache and remove the secondary lead
            queryClient.setQueryData<ILead>([queryKeys.leads, variables.primary_lead_uuid], data);
            queryClient.removeQueries([queryKeys.leads, variables.secondary_lead_uuid]);

            // Invalidate all queries that might be affected by the merge
            queryClient.invalidateQueries(queryKeys.showings);
            queryClient.invalidateQueries(queryKeys.conversations);
            queryClient.invalidateQueries([queryKeys.messages, variables.primary_lead_uuid]);

            addNotification('Leads merged', 'success');
        },
        onError: (error) => {
            const errorMessage = getApiErrorMessage(error) || 'Failed to merge leads';
            addNotification(errorMessage, 'error');
        },
    });
};
