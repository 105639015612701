import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ILead, ELeadStage } from 'src/services/api/leads/types';
import { useNotifications } from 'src/notifications';
import { apiClient } from 'src/services/api/client';
import { getApiErrorMessage } from 'src/services/api/utils';
import { queryKeys } from 'src/services/api/constants';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';

export type IUpdateLeadStageResponse = {
    lead: ILead;
};

export const useUpdateLeadStage = (leadUuid: string) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<ILead, IStandardErrorResponse, ELeadStage>(
        async (payload) => {
            const path = `${GET_LEADS_PATH}/${leadUuid}/stages`;
            const res = await apiClient(path, {
                method: 'put',
                json: { stage: payload },
            }).json<IUpdateLeadStageResponse>();

            return res.lead;
        }, {
            onSuccess: (data) => {
                queryClient.setQueryData<ILead[]>(queryKeys.leads, (oldData) => {
                    if (!oldData) {
                        return [];
                    }

                    return oldData.map((lead) => {
                        if (lead.uuid === leadUuid) {
                            return data;
                        }

                        return lead;
                    });
                });
                queryClient.setQueryData<ILead>([queryKeys.leads, leadUuid], data);

                addNotification('Lead stage updated', 'success');
            },
            onError: (error) => {
                const errorMessage = getApiErrorMessage(error) || 'Failed to update lead stage';
                addNotification(errorMessage, 'error');
            },
        });
};
