import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';

import { useAuthenticatedState } from 'src/authenticated-state/context';
import { Flex } from 'src/components/flex';

export default function ArchivedLeadsStatus() {
    const { showArchived, setShowArchived, leadsUpdatedAt } = useAuthenticatedState();

    const [toggledArchivedAt, setToggledArchivedAt] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (
            isLoading &&
            toggledArchivedAt &&
            (toggledArchivedAt < new Date(leadsUpdatedAt).toISOString())
        ) {
            setIsLoading(false);
        }
    }, [leadsUpdatedAt, toggledArchivedAt, isLoading]);

    return (
        <Flex width="100%" p={2} justifyContent="center" alignItems="center">
            <Typography >
                Archived leads are {showArchived ? 'visible' : 'hidden'}.
            </Typography>

            <LoadingButton
                variant="text"
                onClick={() => {
                    setToggledArchivedAt(new Date().toISOString());
                    setIsLoading(true);
                    setShowArchived(prev => !prev);
                }}
                loading={isLoading}
                loadingPosition="start"
                startIcon={<ArchiveIcon />}
                data-testid="show-archived-toggle"
            >
                {showArchived ? 'Hide' : 'Show'} archived leads
            </LoadingButton>
        </Flex>
    );
}
