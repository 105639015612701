import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import LandlordProtectedRoute from 'src/components/auth/LandlordProtectedRoute';
import ConversationsPage from 'src/pages/landlord/conversations/ConversationsPage';
import LeadsPage from 'src/pages/landlord/leads/LeadsPage';
import LeadMessagesPage from 'src/pages/landlord/leads/messages/LeadMessagesPage';

const PropertiesPage = lazy(() => import('src/pages/landlord/properties/PropertiesPage'));
const SettingsPage = lazy(() => import('src/pages/landlord/settings/SettingsPage'));
const PlaybooksPage = lazy(() => import('src/pages/landlord/playbooks/index/PlaybooksPage'));
const NewPlaybookPage = lazy(() => import('src/pages/landlord/playbooks/new/NewPlaybookPage'));
const EditPlaybookPage = lazy(() => import('src/pages/landlord/playbooks/edit/EditPlaybookPage'));
const ToursPage = lazy(() => import('src/pages/landlord/tours/ToursPage'));
const AnalyticsPage = lazy(() => import('src/pages/landlord/analytics/AnalyticsPage'));
const LandlordTeam = lazy(() => import('src/pages/landlord/team/LandlordTeam'));
const UnmatchedListingsPage = lazy(() => import('src/pages/landlord/unmatched-listings/UnmatchedListingsPage'));
import TestErrorBoundaryPage from 'src/pages/test-error-boundary/TestErrorBoundaryPage';

/**
 * Routes for the landlord protected pages.
 * They have to be nested under the `/landlord/inner` route.
 */
export const landlordProtectedRoutes = [
    <Route key="analytics" path="analytics" element={<LandlordProtectedRoute><AnalyticsPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks" path="playbooks" element={<LandlordProtectedRoute><PlaybooksPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks/new" path="playbooks/new" element={<LandlordProtectedRoute><NewPlaybookPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks/:id" path="playbooks/:id" element={<LandlordProtectedRoute><EditPlaybookPage /></LandlordProtectedRoute>} />,
    <Route key="properties" path="properties" element={<LandlordProtectedRoute><PropertiesPage /></LandlordProtectedRoute>} />,
    <Route key="messages" path="messages" element={<LandlordProtectedRoute><ConversationsPage /></LandlordProtectedRoute>} />,
    <Route key="messages/from-lead" path="messages/from-lead" element={<LandlordProtectedRoute><LeadMessagesPage /></LandlordProtectedRoute>} />,
    <Route key="tours" path="tours" element={<LandlordProtectedRoute><ToursPage /></LandlordProtectedRoute>} />,
    <Route key="settings" path="settings" element={<LandlordProtectedRoute><SettingsPage /></LandlordProtectedRoute>} />,
    <Route key="leads/all" path="leads/all" element={<LandlordProtectedRoute><LeadsPage /></LandlordProtectedRoute>} />,
    <Route key="team" path="team" element={<LandlordProtectedRoute><LandlordTeam /></LandlordProtectedRoute>} />,
    <Route key="unmatched-listings" path="unmatched-listings" element={<LandlordProtectedRoute><UnmatchedListingsPage /></LandlordProtectedRoute>} />,
    <Route key="test-error-boundary" path="test-error-boundary" element={<LandlordProtectedRoute><TestErrorBoundaryPage /></LandlordProtectedRoute>} />,
];