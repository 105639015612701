import React, { useEffect, useMemo, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ArrowRightIcon from '@mui/icons-material/ChevronRightRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';

import { Flex } from 'src/components/flex';
import { CalendarIcon, LeadsIcon, PropertiesIcon, UsersIcon } from 'src/icons';
import { useAuthenticatedState } from 'src/authenticated-state/context';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import { LEAD_INGESTION_METHOD_OPTIONS } from 'src/services/api/leads/constants';
import SelectLabels from 'src/components/input/select-labels/SelectLabels';
import AutocompleteMultiple from 'src/components/input/autocomplete-multiple/AutocompleteMultiple';
import SelectDateRange from 'src/pages/landlord/leads/components/header/SelectDateRange';
import { LEAD_STAGES, type ELeadStage } from 'src/services/api';
import { StageChip } from 'src/components/input/select-lead-stage/SelectLeadStage';

enum FilteringBy {
    Assignee = 'Assignee',
    IngestionSource = 'Ingestion Source',
    IngestionMethod = 'Ingestion Method',
    DateRange = 'Date Range',
    LeadStage = 'Lead Stage',
    LeadLabel = 'Lead Label',
    Property = 'Property',
    PropertyLabel = 'Property Label',
}

const teamMemberFilters = [FilteringBy.Assignee];
const ingestionFilters = [FilteringBy.IngestionSource, FilteringBy.IngestionMethod];
const dateFilters = [FilteringBy.DateRange];
const leadFilters = [FilteringBy.LeadStage, FilteringBy.LeadLabel];
const propertyFilters = [FilteringBy.Property, FilteringBy.PropertyLabel];

type Props = {
    anchorEl?: HTMLElement | null;
}

export default function LeadsFilterModal({ anchorEl }: Props) {
    const { leadIngestionSources } = useAuthenticatedState();
    const {
        teamMembers,
        properties,
        isFilterOpen,
        setFilterOpen,
        filters,
        setFilters,
        resetFilters,
    } = useLeadsPageContext();

    const searchRef = useRef<HTMLInputElement>(null);

    const [filteringBy, setFilteringBy] = useState<FilteringBy>();

    const handleClose = () => {
        setFilterOpen(false);
        setFilteringBy(undefined);
    };

    // Focus on the search input when the modal is opened
    useEffect(() => {
        if (isFilterOpen) {
            setTimeout(() => searchRef.current?.focus(), 0);
        }
    }, [isFilterOpen]);

    const teamMemberOptions = useMemo(() => {
        return teamMembers.map(member => ({
            value: String(member.id),
            label: member.name,
        }));
    }, [teamMembers]);

    const ingestionSourceOptions = useMemo(() => {
        return leadIngestionSources.map(source => ({
            value: source,
            label: source,
        }));
    }, [leadIngestionSources]);

    const propertyOptions = useMemo(() => {
        const _propertyOptions = properties?.map(property => ({
            value: String(property.id),
            label: property.name,
        })) || [];

        return [
            { value: 'none', label: 'None' },
            ..._propertyOptions,
        ];
    }, [properties]);

    const handleToggleLeadStage = (stage: ELeadStage) => {
        setFilters(prev => {
            if (prev.leadStages.includes(stage)) {
                return { ...prev, leadStages: prev.leadStages.filter(o => o !== stage) };
            } else {
                return { ...prev, leadStages: [...prev.leadStages, stage] };
            }
        });
    };

    return (
        <Menu
            onClose={handleClose}
            open={isFilterOpen && !!anchorEl}
            anchorEl={anchorEl}
            hideBackdrop={false}
            slotProps={{
                root: {
                    sx: {
                        '@media (max-width: 1400px)': {
                            p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }
                    }
                },
                paper: {
                    sx: {
                        width: 400,
                        maxWidth: '100%',
                        '@media (max-width: 1400px)': {
                            position: 'static', width: 500, maxWidth: '100%'
                        }
                    }
                }
            }}
        >
            <Flex alignItems="center" p={2}>
                {!!filteringBy && (
                    <IconButton onClick={() => setFilteringBy(undefined)} edge="start">
                        <ArrowBackRounded />
                    </IconButton>
                )}

                <Typography>
                    Filter by
                </Typography>
                <Typography>
                    {filteringBy}
                </Typography>
            </Flex>

            {!filteringBy && (
                <div>
                    {teamMemberFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <UsersIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}

                    <Divider />

                    {ingestionFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <DownloadRoundedIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}

                    <Divider />

                    {dateFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <CalendarIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}

                    <Divider />

                    {leadFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <LeadsIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}

                    <Divider />

                    {propertyFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <PropertiesIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}
                </div>
            )}

            {!!filteringBy && (
                <div>
                    {filteringBy === FilteringBy.Assignee && (
                        <Flex p={1}>
                            <AutocompleteMultiple
                                placeholder="Select Assignees"
                                options={teamMemberOptions}
                                value={filters.assigneeIds}
                                onChange={(assigneeIds) => {
                                    setFilters(prev => ({
                                        ...prev,
                                        assigneeIds: assigneeIds || [],
                                        hasNoProperty: false,
                                    }));
                                }}
                                size="small"
                                fullWidth
                                data-testid="filter-by-assignee-select"
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.IngestionSource && (
                        <Flex p={1}>
                            <AutocompleteMultiple
                                placeholder="Select Ingestion Sources"
                                options={ingestionSourceOptions}
                                value={filters.ingestionSources}
                                onChange={(ingestionSources) => {
                                    setFilters(prev => ({
                                        ...prev,
                                        ingestionSources: ingestionSources || [],
                                        hasNoProperty: false,
                                    }));
                                }}
                                size="small"
                                fullWidth
                                data-testid="filter-by-ingestion-source"
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.IngestionMethod && (
                        <Flex p={1}>
                            <AutocompleteMultiple
                                placeholder="Select Ingestion Methods"
                                options={LEAD_INGESTION_METHOD_OPTIONS}
                                value={filters.ingestionMethods}
                                onChange={(ingestionMethods) => {
                                    setFilters(prev => ({
                                        ...prev,
                                        ingestionMethods: ingestionMethods || [],
                                        hasNoProperty: false,
                                    }));
                                }}
                                size="small"
                                fullWidth
                                data-testid="filter-by-ingestion-method"
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.DateRange && (
                        <Flex p={1}>
                            <SelectDateRange />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.LeadStage && LEAD_STAGES.map(stage => {
                        return (
                            <MenuItem key={stage} onClick={() => handleToggleLeadStage(stage)}>
                                <Flex alignItems="center">
                                    <Checkbox
                                        checked={filters.leadStages.includes(stage)}
                                        sx={{ padding: 0, paddingLeft: 1 }}
                                    />
                                    <StageChip value={stage} />
                                </Flex>
                            </MenuItem>
                        );
                    })}

                    {filteringBy === FilteringBy.LeadLabel && (
                        <Flex p={1}>
                            <SelectLabels
                                type="lead"
                                placeholder="Select lead labels..."
                                value={filters.leadLabelIds}
                                onChange={(leadLabelIds) => setFilters(prev => ({ ...prev, leadLabelIds: leadLabelIds || [] }))}
                                size="small"
                                fullWidth
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.Property && (
                        <Flex p={1}>
                            <AutocompleteMultiple
                                placeholder="Select properties"
                                options={propertyOptions}
                                value={filters.propertyIds}
                                onChange={(propertyIds) => {
                                    if (propertyIds?.includes('none')) {
                                        setFilters(prev => ({
                                            ...prev,
                                            propertyIds: [],
                                            hasNoProperty: true,
                                        }));

                                        handleClose();
                                    } else {
                                        setFilters(prev => ({
                                            ...prev,
                                            propertyIds: propertyIds || [],
                                            hasNoProperty: false,
                                        }));
                                    }
                                }}
                                size="small"
                                fullWidth
                                data-testid="filter-by-property-select"
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.PropertyLabel && (
                        <Flex p={1}>
                            <SelectLabels
                                type="property"
                                placeholder="Select property labels..."
                                value={filters.propertyLabelIds}
                                onChange={(propertyLabelIds) => setFilters(prev => ({ ...prev, propertyLabelIds: propertyLabelIds || [] }))}
                                size="small"
                                fullWidth
                                data-testid="filter-by-property-label-select"
                            />
                        </Flex>
                    )}
                </div>
            )}

            <Divider />

            <Flex width="100%" p={1} justifyContent="space-between">
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        resetFilters();
                        handleClose();
                    }}
                    size="small"
                    fullWidth
                    data-testid="clear-all-filters-button"
                >
                    Clear All
                </Button>
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={handleClose}
                    size="small"
                    fullWidth
                    data-testid="close-filters-modal-button"
                >
                    Close
                </Button>
            </Flex>
        </Menu>
    );
}
