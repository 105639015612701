import React, { useEffect, useMemo, useState, createContext, useContext } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import type { IOtherUser, IUser } from 'src/api/users-api';
import type { ILead, IProperty, ELeadStage } from 'src/services/api';
import type { IShowing } from 'src/api/landlord-showings-api';
import type { IPropertyLabel } from 'src/services/api';
import { type LeadsGrouping, LeadsSortColumn } from 'src/pages/landlord/leads/types';
import { useShowings, usePropertyLabels, useProperties } from 'src/services/api';
import { useAuthenticatedState } from 'src/authenticated-state/context';
import { searchLeads } from 'src/pages/landlord/leads/utils/search-leads';
import { sortLeads } from 'src/pages/landlord/leads/utils/sort-leads';
import { filterLeads } from 'src/pages/landlord/leads/utils/filter-leads';

export enum LeadsDateFilter {
    Month = 'MONTH',
    Week = 'WEEK',
    Day = 'DAY',
}

export const leadDateFilterLabels: Record<LeadsDateFilter, string> = {
    [LeadsDateFilter.Month]: 'New this month',
    [LeadsDateFilter.Week]: 'New this week',
    [LeadsDateFilter.Day]: 'New today',
};

const leadDateFilters = Object.values(LeadsDateFilter) as LeadsDateFilter[];
export const leadsDateFilterOptions = leadDateFilters.map((filter) => ({
    value: filter,
    label: leadDateFilterLabels[filter]
}));

export type ILeadFilters = {
    assigneeIds: string[];

    ingestionSources: string[];
    ingestionMethods: string[];

    dateRange: LeadsDateFilter | undefined;

    leadStages: ELeadStage[];
    leadLabelIds: string[];

    propertyIds: string[];
    // When true, only conversations with no property will be shown.
    // We can overload the propertyId field to also represent the absence of a property.
    // But, this is more explicit and less error-prone.
    hasNoProperty: boolean;
    propertyLabelIds: string[];
};

// Helper types to make the code more concise.
// Must not be exported!
type Action<T> = React.Dispatch<React.SetStateAction<T>>;
type Context = ILeadsPageContext;

export interface ILeadsPageContext {
    loggedInUser: IUser;
    teamMembers: IOtherUser[];
    allLeads: ILead[];
    realLeads: ILead[];
    syntheticLead: ILead | undefined;
    leads: ILead[];
    properties: Pick<IProperty, 'id' | 'name'>[];
    propertyLabels: IPropertyLabel[];
    showings: IShowing[];

    grouping?: LeadsGrouping;
    setGrouping: (grouping?: LeadsGrouping) => void;
    sortBy: LeadsSortColumn;
    setSortBy: Action<Context['sortBy']>;
    sortDirection: 'asc' | 'desc';
    setSortDirection: Action<Context['sortDirection']>;

    search: string;
    setSearch: Action<Context['search']>;
    isFilterOpen: boolean;
    setFilterOpen: Action<Context['isFilterOpen']>;
    filters: ILeadFilters;
    setFilters: Action<Context['filters']>;
    resetFilters: () => void;
    isFilterOrSearchActive: boolean;

    isCreateLeadModalOpen: boolean;
    setIsCreateLeadModalOpen: Action<Context['isCreateLeadModalOpen']>;
    isEditLeadLabelsModalOpen: boolean;
    setIsEditLeadLabelsModalOpen: Action<Context['isEditLeadLabelsModalOpen']>;

    selectedLeadUuids: string[];
    setSelectedLeadUuids: Action<Context['selectedLeadUuids']>;
}

// @ts-expect-error lazy init
export const LeadsPageContext = createContext<ILeadsPageContext>();

export const useLeadsPageContext = () => {
    const context = useContext(LeadsPageContext);

    if (!context) {
        throw new Error('useLeadsPageContext must be used within a LeadsPageProvider');
    }

    return context;
};

export const INITIAL_FILTERS: ILeadFilters = {
    assigneeIds: [],
    ingestionSources: [],
    ingestionMethods: [],
    dateRange: undefined,
    leadStages: [],
    leadLabelIds: [],
    propertyIds: [],
    hasNoProperty: false,
    propertyLabelIds: [],
};

const getLeadsFilterFromLocalStorage = (userId: number): ILeadFilters => {
    const filterJSON = window.localStorage.getItem(`leadFilters-${userId}`);
    const filter = filterJSON ? JSON.parse(filterJSON) : INITIAL_FILTERS;
    return { ...INITIAL_FILTERS, ...filter };
};

const setLeadsFilterToLocalStorage = (userId: number, filters: ILeadFilters) => {
    window.localStorage.setItem(`leadFilters-${userId}`, JSON.stringify(filters));
};

interface Props {
    children: React.ReactNode;
}

export default function LeadsPageProvider({ children }: Props) {
    const { loggedInUser, showArchived, leads } = useAuthenticatedState();

    const [isCreateLeadModalOpen, setIsCreateLeadModalOpen] = useState(false);
    const [isEditLeadLabelsModalOpen, setIsEditLeadLabelsModalOpen] = useState(false);

    const { data: properties } = useProperties();
    const { data: propertyLabels } = usePropertyLabels();
    const { data: showings } = useShowings();

    const teamMembers = useMemo(() => {
        if (!loggedInUser?.leasing_team_members) {
            return [];
        }

        return loggedInUser.leasing_team_members;
    }, [loggedInUser]);

    const realLeads = useMemo(() => {
        if (!leads) {
            return [];
        }

        return leads.filter((lead) => !lead.is_synthetic);
    }, [leads]);
    const syntheticLead = useMemo(() => {
        if (!leads) {
            return;
        }

        return leads.find((lead) => lead.is_synthetic);
    }, [leads]);

    const [search, setSearch] = useState('');

    const [filters, setFilters] = useState<ILeadFilters>(getLeadsFilterFromLocalStorage(loggedInUser.id));
    const [isFilterOpen, setFilterOpen] = useState(false);
    useEffect(() => {
        setFilters(getLeadsFilterFromLocalStorage(loggedInUser.id));
    }, [loggedInUser, setFilters]);

    useEffect(() => {
        setLeadsFilterToLocalStorage(loggedInUser.id, filters);
    }, [filters, loggedInUser]);

    const [sortBy, setSortBy] = useState<LeadsSortColumn>(LeadsSortColumn.INSERTED_AT);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
    const [grouping, setGrouping] = useState<LeadsGrouping>();

    const [selectedLeadUuids, setSelectedLeadUuids] = useState<string[]>([]);

    const filteredLeads = useMemo(() => {
        let _filteredLeads = leads || [];
        _filteredLeads = _filteredLeads.filter((lead) => {
            if (showArchived) { return true; }
            return !lead.is_archived;
        });

        if (!filters) {
            return _filteredLeads;
        }

        _filteredLeads = filterLeads(_filteredLeads, properties, filters);
        _filteredLeads = searchLeads(_filteredLeads, properties, search);

        return _filteredLeads;
    }, [showArchived, leads, properties, search, filters]);

    const filteredAndSortedLeads = useMemo(() => {
        return sortLeads(filteredLeads, sortBy, sortDirection);
    }, [filteredLeads, sortBy, sortDirection]);

    // Reset selected leads when the tab, search or filter changes
    useEffect(() => {
        setSelectedLeadUuids([]);
    }, [search, filters]);

    const isLoading = !loggedInUser ||
        !leads ||
        !properties ||
        !propertyLabels ||
        !showings;
    if (isLoading) {
        return (
            <LinearProgress sx={{ position: 'fixed', top: 0 }} />
        );
    }

    const resetFilters = () => {
        setFilters(INITIAL_FILTERS);
    };

    const isFilterOrSearchActive = !!search ||
        !!filters?.dateRange ||
        !!filters?.assigneeIds.length ||
        !!filters?.ingestionSources.length ||
        !!filters?.ingestionMethods.length ||
        !!filters?.hasNoProperty ||
        !!filters?.propertyIds.length ||
        !!filters?.propertyLabelIds.length;

    return (
        <LeadsPageContext.Provider value={{
            loggedInUser,
            teamMembers,
            allLeads: leads || [],
            realLeads,
            syntheticLead,
            leads: filteredAndSortedLeads,
            properties,
            propertyLabels,
            showings,

            sortBy,
            setSortBy,
            sortDirection,
            setSortDirection,
            grouping,
            setGrouping,

            search,
            setSearch,
            filters,
            setFilters,
            resetFilters,
            isFilterOpen,
            setFilterOpen,
            isFilterOrSearchActive,

            isCreateLeadModalOpen,
            setIsCreateLeadModalOpen,
            isEditLeadLabelsModalOpen,
            setIsEditLeadLabelsModalOpen,

            selectedLeadUuids,
            setSelectedLeadUuids,
        }}
        >
            {children}
        </LeadsPageContext.Provider>
    );
}
