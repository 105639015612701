import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Linkify from 'react-linkify';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import type { IMessage, ICreateShowingRequest } from 'src/services/api';
import { EMessageChannel } from 'src/services/api';
import type { IShowing } from 'src/api/landlord-showings-api';
import ReffieTextTooltip from 'src/components/ReffieTextTooltip';
import MessageHeader from 'src/pages/landlord/leads/messages/components/messages-container/MessageHeader';
import MessageSentStatusIcon from 'src/pages/landlord/leads/messages/components/messages-container/MessageSentStatusIcon';
import OpenHouseAutomationModal from 'src/pages/landlord/leads/messages/components/messages-container/OpenHouseAutomationModal';
import { type IRegion, parseMessageLineIntoRegions } from 'src/pages/landlord/leads/messages/components/messages-container/utils/parse-message-line-into-regions';
import CreateOrUpdateTourModal from 'src/components/create-or-update-tour-modal/CreateOrUpdateTourModal';
import { PlaybookUUID } from 'src/playbooks/playbook-templates';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/hooks';
import { Flex, FlexColumn } from 'src/components/flex';
import convertFileSize from 'src/utils/convert-file-size';

import styles from 'src/pages/landlord/leads/messages/components/messages-container/Message.module.css';

dayjs.extend(relativeTime);

interface Props {
    message: IMessage,
}

/**
 * Display a single message
 */
export default function Message({
    message,
}: Props) {
    const navigate = useNavigate();

    const { loggedInUser, lead, showings, attachments } = useLeadMessagesPageContext();

    /**
     * When we have an existin upcoming showing, by default we will edit that showing instead of creating a new one
     */
    const upcomingShowingsForThisLead = showings.filter((showing: IShowing) => {
        return (showing.lead_uuid === lead.uuid &&
            showing.property_id === lead.property_id &&
            showing.start_time > new Date().toISOString());
    });
    const upcomingShowingId = upcomingShowingsForThisLead.length > 0 ? upcomingShowingsForThisLead[0].id : -1;
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [showingAutomationTarget, setShowingAutomationTarget] = useState<ICreateShowingRequest>();

    const handleCloseOpenHouseAutomationModal = (goToAutomations: boolean) => {
        if (!showingAutomationTarget) {
            throw new Error('showingAutomationTarget is null');
        }
        const propertyId = showingAutomationTarget.property_id;
        setShowingAutomationTarget(undefined);
        if (goToAutomations) {
            const playbookUuid = PlaybookUUID.ManualSendEmailNotBookedShowing;
            const path = '/landlord/inner/playbooks/new';
            const url = `${path}?template_uuid=${playbookUuid}&property_id=${propertyId}`;
            navigate(url);
        }
    };

    const isSystemMessage = message.system_message_type;

    let className;
    if (isSystemMessage) {
        className = [styles.systemMessage];
    } else if (message.direction === 'out') {
        className = [styles.messageFromAgent];
    } else if (message.direction === 'in') {
        className = [styles.messageFromLead];
    }

    let iconBackgroundColor;
    let iconColor;
    let downloadIconColor;
    if (message.direction === 'out') {
        iconBackgroundColor = '#9EFFDD';
        iconColor = '#000000';
        downloadIconColor = '#9EFFDD';
    } else {
        iconBackgroundColor = '#10B981';
        iconColor = '#FFFFFF';
        downloadIconColor = '#ADADAD';
    }

    const messageLines = message.contents.split('\n').map((line: string, i: number) => {
        const regions = parseMessageLineIntoRegions(line, i);
        const spans = regions.map((region: IRegion) => {
            if (region.parsedDate) {
                return <ReffieTextTooltip key={`tooltip-${region.key}`} title="Click to schedule a showing">
                    <span key={region.key} className={styles.nlpTagDate}
                        role="button"
                        onClick={() => setSelectedDate(region.parsedDate?.toISOString() || null)}
                    >{region.text}</span>
                </ReffieTextTooltip>;
            } else {
                return <span key={region.key}>{region.text}</span>;
            }
        });
        return <div key={`line-${i}`} className={styles.messageLine}>{spans}</div>;
    });

    const handleCloseShowingModal = () => {
        setSelectedDate(null);
        setShowingAutomationTarget(undefined);
    };

    const messageAttachment = attachments?.find((attachment) => attachment.message_uuid === message.uuid);
    const fileExtension = messageAttachment?.mimetype?.split('/')[1].toUpperCase();

    let attachmentUnit = 'bytes';
    if ((messageAttachment && messageAttachment.file_size >= 1024)) {
        attachmentUnit = 'KB';
    }

    const attachmentUrl = messageAttachment?.download_url;

    return (
        <div className={clsx(styles.messageBlock, className)}>
            {showingAutomationTarget && (
                <OpenHouseAutomationModal
                    open={showingAutomationTarget !== null}
                    onClose={handleCloseOpenHouseAutomationModal}
                />
            )}

            {selectedDate && (
                <CreateOrUpdateTourModal
                    open={selectedDate !== null}
                    onClose={handleCloseShowingModal}
                    selectedLeadUuid={lead.uuid}
                    landlordUserId={loggedInUser.id}
                    leads={[lead]}
                    initDateTime={selectedDate}
                    initPropertyId={lead.property_id}
                    showingId={upcomingShowingId === -1 ? undefined : upcomingShowingId}
                    sourceMessageUuid={message.uuid}
                    onCreated={setShowingAutomationTarget}
                />
            )}

            <FlexColumn
                alignItems={isSystemMessage ? 'center' : message.direction === 'in' ? 'start' : 'end'}
                width={500}
                maxWidth="75%"
            >
                <div className={styles.messageContentsOuter}>
                    {!isSystemMessage && <MessageHeader message={message} />}

                    <div className={styles.messageBody}>
                        <Linkify>{messageLines}</Linkify>
                    </div>

                    <div className={styles.messageMetadata}>
                        <div className={styles.messageStatus}>
                            {message.direction === 'in' && message.channel === EMessageChannel.SMS && (
                                <MessageSentStatusIcon message={message} />
                            )}
                        </div>
                    </div>
                </div>

                {messageAttachment && (
                    <div key={messageAttachment.id} className={styles.messageContentsOuter}>
                        <MessageHeader message={message} />
                        <Flex justifyContent="space-between" mt={1} gap={8}>
                            <Flex gap={2}>
                                <Flex alignItems="center">
                                    <Avatar sx={{ bgcolor: iconBackgroundColor }}>
                                        <InsertDriveFileIcon sx={{ fontSize: 20, color: iconColor }} />
                                    </Avatar>
                                </Flex>
                                <FlexColumn>
                                    <Typography variant="body2" className={clsx(styles.attachmentName, styles.messageBody)}>
                                        {messageAttachment.original_filename}
                                    </Typography>
                                    <Typography variant="caption" className={clsx(styles.attachmentDetails, styles.messageBody, className)}>
                                        {convertFileSize(messageAttachment.file_size)} {attachmentUnit} &#x2022; {fileExtension}
                                    </Typography>
                                </FlexColumn>
                            </Flex>
                            <Flex alignItems="center">
                                <Link href={attachmentUrl} download>
                                    <IconButton>
                                        <FileDownloadOutlinedIcon sx={{ color: downloadIconColor }} />
                                    </IconButton>
                                </Link>
                            </Flex>
                        </Flex>
                    </div>
                )}
            </FlexColumn>
        </div>
    );
}
