import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import { useLeadLabels, useProperties, usePropertyLabels } from 'src/services/api';
import { StringFormat, formatString } from 'src/utils/format-string';
import { LeadsIcon, PropertiesIcon, UsersIcon } from 'src/icons';
import Chip from 'src/components/chip/Chip';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

export default function ConversationsFilterStatus() {
    const { data: leadLabels } = useLeadLabels();
    const { data: properties } = useProperties();
    const { data: propertyLabels } = usePropertyLabels();

    const { teamMembers, filter, setFilter } = useConversationsPageContext();

    const filteringByAssignees = !!filter.assigneeIds.length;
    const assigneeFilterLabel = `Assignee is ${filter.assigneeIds
        .map(assigneeId => `"${teamMembers?.find(member => member.id === Number(assigneeId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    const filteringByLeadStages = !!filter.leadStages.length;
    const leadStagesFilterLabel = `Lead stage is ${filter.leadStages
        .map(stage => `"${formatString(stage, StringFormat.SnakeCase, StringFormat.SentenceCase)}"`)
        .join(' or ')}`;

    const filteringByLeadLabels = !!filter.leadLabelIds.length;
    const leadLabelsFilterLabel = `Lead label is ${filter.leadLabelIds
        .map(labelId => `"${leadLabels?.find(label => label.id === Number(labelId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    const filteringByExcludeLeadLabels = !!filter.excludeLeadLabelIds.length;
    const excludeLeadLabelsFilterLabel = `Lead label is not ${filter.excludeLeadLabelIds
        .map(labelId => `"${leadLabels?.find(label => label.id === Number(labelId))?.name || ''}"`)
        .filter(Boolean)
        .join(' and ')}`;

    const filteringByProperty = !!filter.propertyIds.length;
    const propertyFilterLabel = `Property is ${filter.propertyIds
        .map(propertyId => `"${properties?.find(property => property.id === Number(propertyId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    const filteringByPropertyLabels = !!filter.propertyLabelIds.length;
    const propertyLabelsFilterLabel = `Property label is ${filter.propertyLabelIds
        .map(labelId => `"${propertyLabels?.find(label => label.id === Number(labelId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    return (
        <>
            {filteringByAssignees && (
                <Tooltip title={assigneeFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={assigneeFilterLabel}
                            icon={<UsersIcon />}
                            onDelete={() => setFilter(prev => ({ ...prev, assigneeIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByLeadStages && (
                <Tooltip title={leadStagesFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={leadStagesFilterLabel}
                            icon={<LeadsIcon />}
                            onDelete={() => setFilter(prev => ({ ...prev, leadStages: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByLeadLabels && (
                <Tooltip title={leadLabelsFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={leadLabelsFilterLabel}
                            icon={<LeadsIcon />}
                            onDelete={() => setFilter(prev => ({ ...prev, leadLabelIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByExcludeLeadLabels && (
                <Tooltip title={excludeLeadLabelsFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={excludeLeadLabelsFilterLabel}
                            icon={<LeadsIcon />}
                            onDelete={() => setFilter(prev => ({ ...prev, excludeLeadLabelIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByProperty && (
                <Tooltip title={propertyFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={propertyFilterLabel}
                            icon={<PropertiesIcon />}
                            onDelete={() => setFilter(prev => ({ ...prev, propertyIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filter.hasNoProperty && (
                <Chip
                    variant="outlined"
                    label="Lead has no property"
                    icon={<PropertiesIcon />}
                    onDelete={() => setFilter(prev => ({ ...prev, hasNoProperty: false }))}
                />
            )}

            {filteringByPropertyLabels && (
                <Tooltip title={propertyLabelsFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={propertyLabelsFilterLabel}
                            icon={<PropertiesIcon />}
                            onDelete={() => setFilter(prev => ({ ...prev, propertyLabelIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}
        </>
    );
}
