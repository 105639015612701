export enum EMessageDirection {
  IN = 'in',
  OUT = 'out',
  SYSTEM = 'system',
}

export enum EMessageChannel {
  SMS = 'sms',
  EMAIL = 'email',
  FACEBOOK_MESSENGER = 'fb_messenger',
}

export function isMessageChannel(channel: string): channel is EMessageChannel {
    return [
        EMessageChannel.SMS,
        EMessageChannel.EMAIL,
        EMessageChannel.FACEBOOK_MESSENGER
    ].includes(channel as EMessageChannel);
}

export type IMessageAttachment = {
  id: number,
  message_uuid: string,
  original_filename: string,
  s3_path: string,
  mimetype: string,
  file_size: number,
  file_hash: string,
  inserted_at: string | Date,
  download_url: string;
}

export enum EMessageSentStatus {
  SENT = 'sent',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
}

export type IMessage = {
  uuid?: string;
  channel: EMessageChannel;
  contents: string;
  direction: EMessageDirection;
  /**
   * Only set for outgoing SMS messages
   */
  sent_status?: EMessageSentStatus;

  is_sent_by_automation: boolean;
  is_rental_application_request: boolean;

  inserted_at: string | Date;
  system_message_type?: string;
}

export type ISendMessageResponse = {
    new_message: {
        inserted_at: Date;
        direction: string;
        contents: string;
        is_sent_by_automation: boolean;
    };
}
