import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary, } from '@sentry/react';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { FlexColumn } from 'src/components/flex';

export default function SentryErrorBoundary({ children }: { children: React.ReactNode }) {
    const { pathname } = useLocation();

    const resetErrorRef = useRef<() => void>();

    useEffect(() => {
        if (resetErrorRef.current) {
            resetErrorRef.current();
        }
    }, [pathname]);


    return (
        <ErrorBoundary
            fallback={({ error, componentStack, resetError }) => {
                resetErrorRef.current = resetError;

                return (
                    <div>
                        <Alert severity="error">
                            <Typography>Something went wrong. Please try refreshing the page.</Typography>
                            <Typography>You can let us know about this error by emailing <a href="mailto:support@reffie.me">support@reffie.me</a>.</Typography>

                            <FlexColumn mt={2}>
                                <pre>{String(error)}</pre>
                                <pre>{componentStack}</pre>
                            </FlexColumn>
                        </Alert>
                    </div>
                );
            }}
        >
            {children}
        </ErrorBoundary>
    );
}