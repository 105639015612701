import React, { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { type ILead, useUpdateLeadStages } from 'src/services/api';
import { type ELeadStage } from 'src/services/api';
import { FlexColumn } from 'src/components/flex';
import Dialog from 'src/components/dialog/Dialog';
import Select from 'src/components/input/select/Select';
// import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';
import { selectableLeadStageOptions } from 'src/components/input/select-lead-stage/leadStageOptions';

type Props = {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    leads: ILead[];
};

export default function BulkChangeLeadStageModal({
    open,
    onClose,
    onSuccess,
    leads,
}: Props) {
    const {
        mutateAsync: updateLeads,
        isLoading: isUpdatingLeads,
    } = useUpdateLeadStages();



    const [selectedStage, setSelectedStage] = useState<ELeadStage | null>(null);

    const save = async () => {
        if (!selectedStage) {
            return;
        }

        await updateLeads(leads.map(lead => ({
            ...lead,
            stage: selectedStage
        })));

        onSuccess();
        onClose();
    };

    // If all leads are the same stage, pre-select that stage
    useEffect(() => {
        const stages = leads.map(lead => lead.stage);
        const stage = stages[0];
        const areAllLeadsTheSameStage = new Set(stages).size === 1;

        if (areAllLeadsTheSameStage) {
            const currentStage = stage;
            setSelectedStage(currentStage);
        }
    }, []);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Change Stage</DialogTitle>
            <DialogContent>
                <FlexColumn pt={2}>
                    <Select
                        label="Stage"
                        placeholder="Select Stage"
                        withFadedPlaceholder
                        options={selectableLeadStageOptions}
                        value={selectedStage}
                        onChange={setSelectedStage}
                    />

                    <DialogContentText mt={2}>
                        This action will change the stage of {leads.length} {pluralize('leads', leads.length)}.
                    </DialogContentText>
                </FlexColumn>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                    disabled={isUpdatingLeads}
                >
                    Cancel
                </Button>

                <LoadingButton
                    variant="contained"
                    onClick={save}
                    disabled={!selectedStage}
                    loading={isUpdatingLeads}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
