import React, { useMemo, useRef, useState } from 'react';
import Chip, { type ChipProps } from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ELeadStage } from 'src/services/api';
import { leadStageOptions, selectableLeadStageOptions } from 'src/components/input/select-lead-stage/leadStageOptions';
import { leadStageColors } from 'src/components/input/select-lead-stage/leadStageColors';

import styles from 'src/components/input/select-lead-stage/SelectLeadStage.module.css';

type StageChipProps = ChipProps & {
    value: ELeadStage,
};

export function StageChip({ value, ...props }: StageChipProps) {
    const option = leadStageOptions.find((o) => o.value === value);
    const label = option?.label || value;

    return (
        <Chip
            label={label}
            variant="filled"
            clickable
            {...props}
            classes={{
                root: styles.chip,
                label: styles.chipLabel,
            }}
            sx={{
                color: `${leadStageColors[value].textColor}`,
                bgcolor: `${leadStageColors[value].backgroundColor}`,
                '&:hover': {
                    bgcolor: `${leadStageColors[value].backgroundColor}`,
                    opacity: props.clickable === false ? 1 : 0.7,
                    cursor: props.clickable === false ? 'default' : 'pointer',
                },
            }}
            data-testid="lead-stage-chip"
        />
    );
}

type Props = {
    value: ELeadStage,
    onChange: (value: ELeadStage) => void,
    disableScrollLock?: boolean,
    clickable?: boolean,
};

export default function SelectLeadStage({
    value: propsValue,
    onChange,
    disableScrollLock,
    clickable = true,
}: Props) {
    const ref = useRef<HTMLDivElement>(null);

    const [isOpened, setOpened] = useState(false);

    const value = useMemo(() => {
        return propsValue || ELeadStage.NOT_SET;
    }, [propsValue]);

    const options = useMemo(() => {
        if (!value || value === ELeadStage.NOT_SET) {
            return leadStageOptions;
        } else {
            return selectableLeadStageOptions;
        }

    }, [value]);

    const handleMenuItemClick = (newValue: ELeadStage) => {
        setOpened(false);
        onChange(newValue);
    };

    return (
        <div ref={ref}>
            <StageChip
                value={value}
                onClick={clickable ? () => setOpened(true) : undefined}
                clickable={clickable}
            />

            {isOpened && (
                <Menu
                    anchorEl={ref.current}
                    open={true}
                    onClose={() => setOpened(false)}
                    disableScrollLock={disableScrollLock}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.value}
                            onClick={() => handleMenuItemClick(option.value)}
                            sx={{ bgcolor: option.value === value ? 'grey.200' : 'transparent' }}
                        >
                            <StageChip value={option.value} />
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </div>
    );
}
