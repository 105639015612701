import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';

import type { ILead, ELeadStage } from 'src/services/api';
import { LeadUtils } from 'src/utils';
import { useUpdateLeadStage } from 'src/services/api';
import { useToggle } from 'src/hooks/use-toggle';
import Flex from 'src/components/flex/Flex';
import SelectLeadStage from 'src/components/input/select-lead-stage/SelectLeadStage';
import CreateOrUpdateLeadModal from 'src/components/create-or-update-lead-modal/CreateOrUpdateLeadModal';

interface Props {
    lead: ILead;
    /**
     * Callback to close the sidebar.
     * If not provided, the close button will not be rendered
     */
    onClose?: () => void;
}

export default function RightSidebarHeader({
    lead,
    onClose,
}: Props) {
    const [isEditingLead, { setTrue: startEditing, setFalse: stopEditingLead }] = useToggle(false);
    const [leadStage, setLeadStage] = useState<ELeadStage>(lead.stage);

    const { mutate: updateLead } = useUpdateLeadStage(lead.uuid);

    const handleLeadStageChange = (stage: ELeadStage) => {
        setLeadStage(stage);
        updateLead(stage);
    };

    useEffect(() => {
        setLeadStage(lead.stage);
    }, [lead.stage]);

    return (
        <Flex p={1} justifyContent="space-between" columnGap={2}>
            <Flex className="ellipsisText" flexWrap="wrap" alignItems="center">
                <Typography
                    variant="h6"
                    onClick={startEditing}
                    sx={{ cursor: 'pointer' }}
                >
                    {LeadUtils.getLeadName(lead)}
                </Typography>

                <SelectLeadStage
                    value={leadStage}
                    onChange={handleLeadStageChange}
                    disableScrollLock
                />
            </Flex>

            {!!onClose && (
                <Flex alignItems="flex-start">
                    <IconButton onClick={onClose} edge="end" data-testid="lead-sidebar-close-button">
                        <CloseIcon />
                    </IconButton>
                </Flex>
            )}

            {isEditingLead && (
                <CreateOrUpdateLeadModal
                    lead={lead}
                    onSave={stopEditingLead}
                    onClose={stopEditingLead}
                    disableScrollLock
                />
            )}
        </Flex>
    );
}
