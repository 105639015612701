import { type UseQueryResult, useQuery } from 'react-query';

import type { IMessage, IMessageAttachment } from 'src/services/api/messages/types';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { GET_MESSAGES_PATH } from 'src/services/api/messages/constants';

type IGetLeadMessagesResponse = {
    messages: IMessage[];
}

type IGetLeadMessagesWithAttachmentsResponse = IGetLeadMessagesResponse & {
    attachments?: IMessageAttachment[];
}

type IGetLeadMessagesOptions ={
    isArchived?: boolean;
    refetchInterval?: number;
    include_attachments?: boolean;
}

export function useLeadMessages(
    leadUuid: string, options: IGetLeadMessagesOptions & { include_attachments: true }
): UseQueryResult<IGetLeadMessagesWithAttachmentsResponse>;
export function useLeadMessages(
    leadUuid: string, options?: IGetLeadMessagesOptions
): UseQueryResult<IMessage[]>;

export function useLeadMessages(
    leadUuid: string,
    options?: IGetLeadMessagesOptions,
) {
    return useQuery(
        [queryKeys.messages, leadUuid],
        async () => {
            const searchParams = new URLSearchParams();
            if (options?.isArchived != null) {
                searchParams.append('is_archived', options.isArchived.toString());
            }
            if (options?.include_attachments) {
                searchParams.append('include_attachments', 'true');
            }

            searchParams.append('lead_uuid', leadUuid);
            const res = await apiClient(GET_MESSAGES_PATH, { searchParams });

            if (options?.include_attachments) {
                return res.json<IGetLeadMessagesWithAttachmentsResponse>();
            } else {
                return (await res.json<IGetLeadMessagesResponse>()).messages;
            }
        }, {
            enabled: !!leadUuid,
            refetchInterval: options?.refetchInterval,
        }
    );
}
