import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { type IConversation } from 'src/api/landlord-messages-api';
import { type IUser } from 'src/api/users-api';
import { type ILead, queryKeys, useUserSettings } from 'src/services/api';
import { sendNewMessageNotification } from 'src/services/service-workers/poll-conversations/send-new-message-notification';

export const useUpdateConversations = () => {
    const queryClient = useQueryClient();

    const { data: userSettings } = useUserSettings();

    const updateConversations = useCallback((updatedConversations: IConversation[]) => {
        const loggedInUser = queryClient.getQueryData<IUser>(queryKeys.loggedInUser);
        const leads = queryClient.getQueryData<ILead[]>(queryKeys.leads);

        if (!loggedInUser || !leads) {
            return;
        }

        const leadsByUuid = new Map(leads?.map((lead) => [lead.uuid, lead]));

        for (const updatedConversation of updatedConversations) {
            const lead = leadsByUuid.get(updatedConversation.lead_uuid);
            sendNewMessageNotification(userSettings, loggedInUser, updatedConversation, lead);
            queryClient.setQueryData([
                'conversations',
                updatedConversation.lead_uuid
            ], updatedConversation);
        }

        queryClient.setQueryData('conversations', (oldData: IConversation[] | undefined) => {
            if (!oldData) {
                return updatedConversations;
            }

            return [
                ...oldData.filter((oldConversation) => {
                    return !updatedConversations.some((updatedConversation) => {
                        return updatedConversation.lead_uuid === oldConversation.lead_uuid;
                    });
                }),
                ...updatedConversations,
            ];
        });
    }, [queryClient, userSettings]);

    return updateConversations;
};
