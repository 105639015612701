import { parsePhoneNumber } from 'libphonenumber-js';

import { type ILead, type IMessage, EMessageChannel } from 'src/services/api';

export class LeadUtils {
    static getDefaultMessageChannel(lead: ILead, messages: IMessage[]): EMessageChannel {
        const lastMessage = messages.at(-1);
        const lastMessageChannel = lastMessage?.channel;

        // Priority: Facebook Messenger > SMS > Email
        let defaultLeadChannel = EMessageChannel.EMAIL;
        if (lead.phone_number) {
            defaultLeadChannel = EMessageChannel.SMS;
        }
        if (lead.facebook_user_id) {
            defaultLeadChannel = EMessageChannel.FACEBOOK_MESSENGER;
        }

        return lastMessageChannel || defaultLeadChannel;
    }

    static getLeadName(lead?: ILead): string {
        if (!lead) {
            return '[Archived lead]';
        }

        if (!lead.name) {
            return '[Unknown name]';
        }

        if (lead.name === 'No Name' && lead.ingestion_source === 'showmojo') {
            return '[Unknown name (ShowMojo)]';
        }

        return lead.name;
    }

    static normalizeLeadInput(lead: {
        email?: string | null;
        phone_number?: string | null;
        ingestion_source?: string | null;
    }) {
        const parsedPhoneNumber = lead.phone_number ?
            parsePhoneNumber(lead.phone_number, 'US') :
            undefined;

        const normalizedLeadInput = {
            email: lead.email ? lead.email.toLowerCase() : null,
            phone_number: parsedPhoneNumber ?
                parsedPhoneNumber.format('E.164') :
                // Fall back to the original phone number if it's not a valid phone number
                // This is not a validation, but a normalization
                lead.phone_number,
            ingestion_source: lead.ingestion_source ?
                normalizeIngestionSourceInput(lead.ingestion_source) :
                null,
        } as {
            email?: string | null;
            phone_number?: string | null;
            ingestion_source?: string | null;
        };

        if (lead.email === undefined) {
            delete normalizedLeadInput.email;
        }
        if (lead.phone_number === undefined) {
            delete normalizedLeadInput.phone_number;
        }
        if (lead.ingestion_source === undefined) {
            delete normalizedLeadInput.ingestion_source;
        }

        return {
            ...lead,
            ...normalizedLeadInput,
        };
    }
}

const normalizeIngestionSourceInput = (_ingestionSource: string) => {
    const ingestionSource = _ingestionSource
        .toLowerCase()
        .trim()
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll('_', '');

    if (ingestionSource.includes('zillow')) {
        return 'zillow';
    }
    if (ingestionSource.includes('rentcafe')) {
        return 'rentcafe';
    }

    return _ingestionSource.toLowerCase();
};

// We will use this function later
// const normalizeIngestionSourceInput = (_ingestionSource: string) => {
//     let ingestionSource = _ingestionSource
//         .toLowerCase()
//         .trim()
//         .replaceAll(' ', '')
//         .replaceAll('-', '')
//         .replaceAll('_', '');

//     if (
//         ingestionSource === 'rent.' ||
//         ingestionSource.includes('rentpath') ||
//         ingestionSource.includes('rent.com')
//     ) {
//         ingestionSource = 'Rent.';
//     }

//     ingestionSource = ingestionSource.replaceAll('.', '');

//     if (ingestionSource.includes('apartment247')) {
//         return 'apartment247.com';
//     } else if (ingestionSource.includes('apartmentadvisor')) {
//         return 'apartmentadvisor.com';
//     } else if (ingestionSource.includes('apartmentfinder')) {
//         return 'apartmentfinder.com';
//     } else if (ingestionSource.includes('apartmentguide')) {
//         return 'apartmentguide.com';
//     } else if (ingestionSource.includes('apartmentlist')) {
//         return 'apartmentlist.com';
//     } else if (ingestionSource.includes('apartmentscom') || ingestionSource === 'apartments') {
//         return 'apartments.com';
//     } else if (ingestionSource.includes('appfolio')) {
//         return 'appfolio.com';
//     } else if (ingestionSource.includes('businessreferral')) {
//         return 'Business Referral';
//     } else if (ingestionSource === 'callin') {
//         return 'Call In';
//     } else if (ingestionSource.includes('collegestudentapartments')) {
//         return 'collegestudentapartments.com';
//     } else if (ingestionSource.includes('craigslist')) {
//         return 'Craigslist';
//     } else if (ingestionSource.includes('currentresidenttransfer')) {
//         return 'Current Resident Transfer';
//     } else if (ingestionSource.includes('driveby')) {
//         // return 'Drive By';
//     } else if (ingestionSource.includes('dwellsy')) {
//         return 'dwellsy.com';
//     } else if (ingestionSource.includes('everyapartment')) {
//         return 'every-apartment.com';
//     } else if (ingestionSource.includes('facebook') || ingestionSource.includes('fbmessenger')) {
//         return 'Facebook';
//     } else if (ingestionSource.includes('google')) {
//         return 'Google';
//     } else if (ingestionSource.includes('housingforwardassistance')) {
//         return 'Housing Forward Assistance';
//     } else if (ingestionSource.includes('inboundcall')) {
//         return 'Inbound Call';
//     } else if (ingestionSource.includes('inboundemail')) {
//         return 'Inbound Email';
//     } else if (ingestionSource.includes('leaseterm')) {
//         // return 'Lease Term';
//     } else if (ingestionSource.includes('locator')) {
//         // return 'Locator';
//     } else if (ingestionSource.includes('lovely')) {
//         // return 'Lovely';
//     } else if (ingestionSource.includes('migratedlead')) {
//         return 'Migrated Lead';
//     } else if (ingestionSource.includes('offsite')) {
//         return 'Off Site Marketing';
//     } else if (ingestionSource.includes('otherwebsite')) {
//         return 'Other Website';
//     } else if (ingestionSource.includes('padmapper')) {
//         return 'padmapper.com';
//     } else if (ingestionSource.includes('priorresident')) {
//         return 'Prior Resident';
//     } else if (ingestionSource.includes('promove')) {
//         return 'promove.com';
//     } else if (ingestionSource.includes('propertyreferral')) {
//         return 'Property Referral';
//     } else if (ingestionSource.includes('propertywebsite')) {
//         return 'Property Website';
//     } else if (ingestionSource.includes('realtorcom')) {
//         return 'realtor.com';
//     } else if (ingestionSource.includes('redfin')) {
//         return 'redfin.com';
//     } else if (ingestionSource.includes('reffiedatabase')) {
//         return 'Reffie Database';
//     } else if (ingestionSource.includes('reffieinboundsms')) {
//         return 'Reffie Inbound SMS';
//     } else if (ingestionSource.includes('rentable')) {
//         return 'rentable.co';
//     } else if (ingestionSource.includes('rentcafe')) {
//         return 'rentcafe.com';
//     } else if (ingestionSource.includes('rentler')) {
//         return 'rentler.com';
//     } else if (ingestionSource.includes('rentlinx')) {
//         return 'rentlinx.com';
//     } else if (ingestionSource.includes('rently')) {
//         return 'rently.com';
//     } else if (ingestionSource.includes('roomsurf')) {
//         return 'roomsurf.com';
//     } else if (ingestionSource === 'self') {
//         return 'Self';
//     } else if (ingestionSource.includes('showmetherent')) {
//         return 'Show Me The Rent';
//     } else if (ingestionSource.includes('showmojo')) {
//         return 'showmojo.com';
//     } else if (ingestionSource.includes('synthetic')) {
//         return 'Synthetic';
//     } else if (ingestionSource.includes('tenantturner')) {
//         return 'tenantturner.com';
//     } else if (ingestionSource.includes('trulia')) {
//         return 'trulia.com';
//     } else if (ingestionSource.includes('turbotenant')) {
//         return 'turbotenant.com';
//     } else if (ingestionSource.includes('uloop')) {
//         return 'uloop.com';
//     } else if (ingestionSource.includes('umovefree')) {
//         return 'umovefree.com';
//     } else if (ingestionSource.includes('walkin')) {
//         return 'Walk-in';
//     } else if (ingestionSource === 'website') {
//         return 'Website';
//     } else if (ingestionSource.includes('wordofmouth')) {
//         return 'Word of Mouth';
//     } else if (ingestionSource.includes('zillow')) {
//         return 'zillow.com';
//     }

//     return _ingestionSource;
// };