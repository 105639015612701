import React, { useEffect, useMemo, useState } from 'react';
import { ArrowLeftRightIcon, MoveRightIcon, TriangleAlertIcon } from 'lucide-react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import { type ILead, useLeadMessages, useMergeLeads } from 'src/services/api';
import { useAuthenticatedState } from 'src/authenticated-state/context';
import { Flex, FlexColumn } from 'src/components/flex';
import Dialog from 'src/components/dialog/Dialog';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';

import SelectLeadStage from 'src/components/input/select-lead-stage/SelectLeadStage';

type Props = {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
};

export default function MergeLeadsModal({
    open,
    onClose,
    onSuccess,
}: Props) {
    const { leadsByUuid } = useAuthenticatedState();
    const { properties, selectedLeadUuids } = useLeadsPageContext();

    const { mutateAsync: mergeLeads, isLoading: isMergingLeads } = useMergeLeads();

    const { data: firstLeadsMessages } = useLeadMessages(selectedLeadUuids[0]);
    const { data: secondLeadsMessages } = useLeadMessages(selectedLeadUuids[1]);

    const [primaryLead, setPrimaryLead] = useState<ILead>();
    const [secondaryLead, setSecondaryLead] = useState<ILead>();

    useEffect(() => {
        setPrimaryLead(leadsByUuid.get(selectedLeadUuids[0]));
        setSecondaryLead(leadsByUuid.get(selectedLeadUuids[1]));
    }, [selectedLeadUuids, leadsByUuid]);

    const propertiesById = useMemo(() => {
        return new Map(properties.map(o => [o.id, o]));
    }, [properties]);

    const switchPrimaryAndSecondaryLeads = () => {
        setPrimaryLead(secondaryLead);
        setSecondaryLead(primaryLead);
    };

    const warnings = useMemo(() => {
        if (!primaryLead || !secondaryLead) {
            return [];
        }

        const _warnings: string[] = [];

        if (firstLeadsMessages?.length && secondLeadsMessages?.length) {
            _warnings.push('Both leads have a message history. Only the messages from the primary lead will be kept.');
        }

        if (primaryLead.notes?.contents && secondaryLead.notes?.contents) {
            _warnings.push('Both leads have notes. Only the notes from the primary lead will be kept.');
        }

        return _warnings;
    }, [primaryLead, secondaryLead, firstLeadsMessages, secondLeadsMessages]);

    if (!primaryLead || !secondaryLead) {
        return null;
    }

    const primaryLeadProperty = propertiesById.get(primaryLead.property_id!);
    const secondaryLeadProperty = propertiesById.get(secondaryLead.property_id!);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle>Merge Leads</DialogTitle>
            <DialogContent>
                <FlexColumn gap={4}>
                    <Alert severity="info">
                        <Typography variant="body2">
                            When merging leads, the primary lead&apos;s information will be kept and the secondary lead&apos;s information will be lost.
                        </Typography>
                        <Typography variant="body2">
                            If some of the information is missing in the primary lead, the secondary lead&apos;s information will be copied over.
                        </Typography>
                    </Alert>

                    <Flex alignItems="stretch">
                        <Box
                            width="100%"
                            component={Paper}
                            elevation={0}
                            border="1px solid var(--border-color)"
                            p={2}
                        >
                            <FlexColumn gap={0.5}>
                                <Typography mb={1} variant="body2" fontWeight={500}>
                                    Primary Lead
                                </Typography>
                                <Typography variant="body1" fontWeight={500}>
                                    {primaryLead.name}
                                </Typography>
                                <Typography variant="body2">
                                    {primaryLead.email || '[Email not set]'}
                                </Typography>
                                <Typography variant="body2">
                                    {primaryLead.phone_number || '[Phone number not set]'}
                                </Typography>
                                <Typography variant="body2">
                                    {primaryLeadProperty?.name || '[Property not set]'}
                                </Typography>
                                <SelectLeadStage value={primaryLead.stage} onChange={() => { }} clickable={false} />
                            </FlexColumn>
                        </Box>

                        <FlexColumn p={1} justifyContent="center" alignItems="center">
                            <IconButton onClick={switchPrimaryAndSecondaryLeads}>
                                <ArrowLeftRightIcon color="black" size={24} />
                            </IconButton>
                        </FlexColumn>

                        <Box
                            width="100%"
                            component={Paper}
                            elevation={0}
                            border="1px solid var(--border-color)"
                            p={2}
                        >
                            <FlexColumn gap={0.5}>
                                <Typography mb={1} variant="body2" fontWeight={500}>
                                    Secondary Lead
                                </Typography>

                                <Typography variant="body1" fontWeight={500}>
                                    {secondaryLead.name}
                                </Typography>
                                <Typography variant="body2">
                                    {secondaryLead.email || '[Email not set]'}
                                </Typography>
                                <Typography variant="body2">
                                    {secondaryLead.phone_number || '[Phone number not set]'}
                                </Typography>
                                <Typography variant="body2">
                                    {secondaryLeadProperty?.name || '[Property not set]'}
                                </Typography>
                                <SelectLeadStage value={secondaryLead.stage} onChange={() => { }} clickable={false} />
                            </FlexColumn>
                        </Box>

                        <FlexColumn p={1} justifyContent="center">
                            <MoveRightIcon size={24} />
                        </FlexColumn>

                        <Box
                            width="100%"
                            component={Paper}
                            elevation={0}
                            border="1px solid var(--border-color)"
                            p={2}
                        >
                            <FlexColumn gap={0.5}>
                                <Typography mb={1} variant="body2" fontWeight={500}>
                                    Merged Lead
                                </Typography>
                                <Typography variant="body1" fontWeight={500}>
                                    {primaryLead.name}
                                </Typography>
                                <Typography variant="body2">
                                    {primaryLead.email || secondaryLead.email || '[Email not set]'}
                                </Typography>
                                <Typography variant="body2">
                                    {primaryLead.phone_number || secondaryLead.phone_number || '[Phone number not set]'}
                                </Typography>
                                <Typography variant="body2">
                                    {primaryLeadProperty?.name || secondaryLeadProperty?.name || '[Property not set]'}
                                </Typography>
                                <SelectLeadStage value={primaryLead.stage} onChange={() => { }} clickable={false} />
                            </FlexColumn>
                        </Box>
                    </Flex>

                    {!!warnings.length && (
                        <Alert severity="warning">
                            {warnings.map((warning, index) => (
                                <Typography key={index} variant="body2">{warning}</Typography>
                            ))}
                        </Alert>
                    )}
                </FlexColumn>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                >
                    Cancel
                </Button>

                <LoadingButton
                    variant="contained"
                    color="warning"
                    startIcon={warnings.length > 0 ? <TriangleAlertIcon color="white" /> : undefined}
                    loading={isMergingLeads}
                    onClick={async () => {
                        await mergeLeads({
                            primary_lead_uuid: primaryLead.uuid,
                            secondary_lead_uuid: secondaryLead.uuid,
                        });
                        onSuccess();
                        onClose();
                    }}
                >
                    <Typography variant="body2" fontWeight={500} color="white">
                        Merge Leads
                    </Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
