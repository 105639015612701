import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Notification from 'src/notifications/Notification';
import { NotificationContext } from 'src/notifications/context';
import type { NotificationType } from 'src/notifications/types';

interface Props {
    children: React.ReactNode;
}

export default function NotificationsProvider({ children }: Props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const errorMessage = searchParams.get('error-message');

    const [notifications, setNotifications] = useState<Map<string, NotificationType>>(new Map());

    const addNotification = useCallback((
        message: NotificationType['message'],
        variant: NotificationType['variant']
    ) => {
        setNotifications((state) => {
            const id = String(Math.random());
            const newState = new Map(state);
            newState.set(id, { message, variant });
            return newState;
        });
    }, []);

    useEffect(() => {
        if (errorMessage) {
            addNotification(errorMessage, 'error');
            setSearchParams(prev => {
                prev.delete('error-message');
                return prev;
            });
        }
    }, [errorMessage, addNotification]);

    return (
        <NotificationContext.Provider value={{ addNotification }}>
            {children}
            {Array.from(notifications)?.map(([id, notification]) => (
                <Notification key={id} severity={notification.variant}>
                    {notification.message}
                </Notification>
            ))}
        </NotificationContext.Provider>
    );
}
