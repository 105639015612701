import React, { useEffect, useMemo, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import type { ELeadStage } from 'src/services/api';
import type { IConversation } from 'src/api/landlord-messages-api';
import { useProperties } from 'src/services/api';
import { useAuthenticatedState } from 'src/authenticated-state/context';
import {
    type TLandlordConversationsContext,
    type TConversationsFilter,
    EConversationTab,
    conversationTabs,
    initialConversationsPagesByCategory,
    conversationCategories,
    EConversationCategory,
} from 'src/pages/landlord/conversations/context/types';
import { useOpenConversation } from 'src/pages/landlord/conversations/context/hooks';
import { LandlordConversationsContext } from 'src/pages/landlord/conversations/context/context';
import { filterConversations } from 'src/pages/landlord/conversations/utils/filter-conversations';
import { getConversationsByCategory } from 'src/pages/landlord/conversations/utils/get-conversations-by-category';
import EmptyConversationsList from 'src/pages/landlord/conversations/components/empty/EmptyConversationsList';

const setTabInLocalStorage = (tab: EConversationTab) => {
    window.localStorage.setItem('landlordConversationsTab', tab);
};

const getTabFromLocalStorage = (): EConversationTab => {
    return (window.localStorage.getItem('landlordConversationsTab') as EConversationTab) || EConversationTab.NEW;
};

export const INITIAL_FILTERS: {
    assigneeIds: string[];
    leadStages: ELeadStage[];
    leadLabelIds: string[];
    excludeLeadLabelIds: string[];
    propertyIds: string[];
    hasNoProperty: boolean;
    propertyLabelIds: string[];
} = {
    assigneeIds: [],
    leadStages: [],
    leadLabelIds: [],
    excludeLeadLabelIds: [],
    propertyIds: [],
    hasNoProperty: false,
    propertyLabelIds: [],
};

const setFilterInLocalStorage = (userId: number, filter: Filter) => {
    window.localStorage.setItem(`conversationsFilter-${userId}`, JSON.stringify(filter));
};

const getFilterFromLocalStorage = (userId: number): Filter => {
    const filterJSON = window.localStorage.getItem(`conversationsFilter-${userId}`);
    const filter = filterJSON ? JSON.parse(filterJSON) : INITIAL_FILTERS;
    return { ...INITIAL_FILTERS, ...filter };
};

const ROWS_PER_PAGE = 25;

type Props = {
    children: React.ReactNode;
};

export default function LandlordConversationsProvider({ children }: Props) {
    const [tab, setTab] = useState(getTabFromLocalStorage);
    const [search, setSearch] = useState('');
    const [isFilterOpen, setFilterOpen] = useState(false);
    const [selectedConversations, setSelectedConversations] = useState<IConversation[]>([]);
    const [focusedConversation, setFocusedConversation] = useState<IConversation>();
    const [pagesByCategory, setPagesByCategory] = useState(initialConversationsPagesByCategory);
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState<HTMLElement>();

    const { loggedInUser, showArchived, leads, conversations } = useAuthenticatedState();
    const { data: properties } = useProperties();

    const [filter, setFilter] = useState<TConversationsFilter>(getFilterFromLocalStorage(loggedInUser.id));
    useEffect(() => {
        setFilterInLocalStorage(loggedInUser.id, filter);
    }, [loggedInUser, filter, setFilter]);

    const leadsByUuid = useMemo(() => {
        if (!leads) {
            return new Map();
        }

        return new Map(leads.map(o => [o.uuid, o]));
    }, [leads]);

    const teamMembers = useMemo(() => {
        if (!loggedInUser?.leasing_team_members) {
            return [];
        }

        return loggedInUser.leasing_team_members;
    }, [loggedInUser]);

    const resetFilter = () => {
        setFilter(INITIAL_FILTERS);
    };

    useEffect(() => {
        setTabInLocalStorage(tab);
    }, [tab]);

    // Reset selected conversations when the tab, search or filter changes
    useEffect(() => {
        setSelectedConversations([]);
        setFocusedConversation(undefined);
    }, [tab, search, filter]);

    useEffect(() => {
        const conversationsById = new Map(conversations.map(o => [o.lead_uuid, o]));
        setSelectedConversations(prev => {
            return prev.map(o => {
                return conversationsById.get(o.lead_uuid) || o;
            });
        });
    }, [conversations]);

    const filteredConversations = useMemo(() => {
        if (!filter) {
            return [];
        }

        return filterConversations({
            properties,
            leadsByUuid,
            conversations,
            tab,
            search,
            filter
        });
    }, [properties, leadsByUuid, conversations, tab, search, filter]);

    const conversationsByTabs = useMemo(() => {
        return conversationTabs.reduce((acc, _tab) => {
            let _filteredConversations = conversations || [];
            if (filter) {
                _filteredConversations = filterConversations({
                    properties,
                    leadsByUuid,
                    conversations,
                    tab: _tab,
                    search,
                    filter
                });
            }

            return {
                ...acc,
                [_tab]: _filteredConversations,
            };
        }, {} as Record<EConversationTab, IConversation[]>);
    }, [properties, leadsByUuid, conversations, search, filter]);

    const categories = useMemo(() => {
        if (showArchived) {
            return conversationCategories;
        } else {
            return conversationCategories.filter(o => o !== EConversationCategory.Archived);
        }
    }, [showArchived]);

    const conversationsByCategory = useMemo(() => {
        return conversationCategories.reduce((acc, cat) => {
            if (!leads) {
                return {
                    ...acc,
                    [cat]: [],
                };
            }

            const tabConversations = conversationsByTabs[tab].map(conversation => ({
                ...conversation,
                is_archived: leads.find(lead => lead.uuid === conversation.lead_uuid)?.is_archived || false,
            }));

            return {
                ...acc,
                [cat]: getConversationsByCategory(tabConversations, cat)
            };
        }, {} as TLandlordConversationsContext['conversationsByCategory']);
    }, [tab, conversationsByTabs, leads]);

    const visibleConversationsByCategory = useMemo(() => {
        return conversationCategories.reduce((acc, cat) => {
            const _conversations = conversationsByCategory[cat];
            const _page = pagesByCategory[cat];
            return {
                ...acc,
                [cat]: _conversations.slice((_page - 1) * 25, _page * 25),
            };
        }, {} as TLandlordConversationsContext['conversationsByCategory']);
    }, [conversationsByCategory, pagesByCategory]);

    const visibleConversations = useMemo(() => {
        return categories
            .map(cat => visibleConversationsByCategory[cat])
            .flat();
    }, [categories, visibleConversationsByCategory]);

    // Reset pages when conversations change
    useEffect(() => {
        setPagesByCategory(initialConversationsPagesByCategory);
    }, [setPagesByCategory, filteredConversations.map(o => o.lead_uuid).join(',')]);

    const openConversation = useOpenConversation(conversationsByCategory);

    const isLoading = !loggedInUser ||
        !leads ||
        !properties ||
        !conversations;
    if (isLoading) {
        return (
            <LinearProgress sx={{ position: 'fixed', top: 0 }} />
        );
    }

    const hasConversations = !!conversations.length;

    const isSearchOrFilterActive = !!search ||
        !!filter?.assigneeIds.length ||
        !!filter?.leadStages.length ||
        !!filter?.leadLabelIds.length ||
        !!filter?.propertyIds.length ||
        !!filter?.propertyLabelIds.length;

    return (
        <LandlordConversationsContext.Provider value={{
            loggedInUser,
            teamMembers,
            leads,
            leadsByUuid,
            properties,

            conversations,
            filteredConversations,

            tab,
            setTab,
            conversationsByTabs,

            search,
            setSearch,
            isFilterOpen,
            setFilterOpen,
            filter,
            setFilter,
            resetFilter,
            isSearchOrFilterActive,

            rowsPerPage: ROWS_PER_PAGE,

            categories,
            pagesByCategory,
            setPagesByCategory,
            conversationsByCategory,
            visibleConversationsByCategory,
            visibleConversations,

            selectedConversations,
            setSelectedConversations,
            focusedConversation,
            setFocusedConversation,

            openConversation,

            mobileMenuAnchorEl,
            setMobileMenuAnchorEl,
        }}
        >
            {hasConversations && children}
            {!hasConversations && <EmptyConversationsList />}
        </LandlordConversationsContext.Provider>
    );
}

type Filter = TLandlordConversationsContext['filter'];
