import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import GroupIcon from '@mui/icons-material/Group';

import type { ILead, ELeadStage } from 'src/services/api';
import { LeadUtils } from 'src/utils';
import { useUpdateLeadStage } from 'src/services/api/leads';
import { prettyifyPhoneNumber } from 'src/common/phone-number-utils';
import { Flex, FlexColumn } from 'src/components/flex';
import TableCell from 'src/components/table/TableCell';
import Menu from 'src/pages/landlord/leads/components/table/LeadsTableRowMenu';
import CopyText from 'src/components/copy-text/CopyText';
import SelectLeadStage from 'src/components/input/select-lead-stage/SelectLeadStage';
import EditableLeadLabels from 'src/components/input/select-lead-labels/EditableLeadLabels';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import { normalizeSource } from 'src/pages/landlord/analytics/utils';

import styles from 'src/pages/landlord/leads/components/table/LeadsTableRow.module.css';
import Checkbox from '@mui/material/Checkbox';

interface Props {
    lead: ILead;
}

export default function DesktopLeadsTableRow({ lead }: Props) {
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        loggedInUser,
        properties,
        selectedLeadUuids,
        setSelectedLeadUuids,
    } = useLeadsPageContext();

    const { mutate: updateLead } = useUpdateLeadStage(lead.uuid);

    const [stage, setStage] = useState<ELeadStage>(lead.stage);

    const property = useMemo(() => {
        return properties?.find((o) => o.id === lead.property_id);
    }, [properties, lead.property_id]);

    const isSharedLead = !!loggedInUser && lead.owner !== loggedInUser.id;
    let ownerName: string | undefined = loggedInUser?.name;
    if (isSharedLead) {
        ownerName = loggedInUser?.leasing_team_members?.find((o) => o.id === lead.owner)?.name;
    }

    const email = lead.email || '';
    const phone = lead.phone_number || '';

    const handleOpenDetails = () => {
        setSearchParams({ uuid: lead.uuid });
    };

    const handleLeadStageChange = (newStage: ELeadStage) => {
        setStage(newStage);
        updateLead(newStage);
    };

    useEffect(() => {
        setStage(lead.stage);
    }, [lead.stage]);

    const currentLeadUuidSelected = searchParams.get('uuid');
    const isSelected = currentLeadUuidSelected === lead.uuid;

    const checkboxSelected = selectedLeadUuids.includes(lead.uuid);
    const checkboxOpacity = selectedLeadUuids.length > 0 ? 1 : 0;

    return (
        <TableRow
            className={clsx(isSelected && styles.selectedLeadRow)}
            sx={{
                borderBottom: '1px solid var(--border-color)',
                '& .MuiCheckbox-root': { opacity: checkboxOpacity },
                '&:hover .MuiCheckbox-root': { opacity: 1 },
            }}
        >
            <TableCell
                width={40}
                className={styles.tableCellWithCheckbox}
                data-testid="checkbox-column"
                onClick={(event) => event.stopPropagation()}
            >
                <Checkbox
                    className={styles.checkbox}
                    checked={checkboxSelected}
                    onClick={(event) => {
                        event.stopPropagation();
                        setSelectedLeadUuids((prev) => {
                            if (prev.includes(lead.uuid)) {
                                return prev.filter((o) => o !== lead.uuid);
                            }
                            return [...prev, lead.uuid];
                        });
                    }}
                    sx={{
                        padding: 0,
                        width: 20,
                        height: 20,
                        '& svg': { fontSize: 20 }
                    }}
                />
            </TableCell>

            <TableCell>
                <Flex alignItems="center">
                    {isSharedLead && (
                        <Tooltip title={`This lead is shared with you by ${ownerName}`}>
                            <GroupIcon color="disabled" fontSize="small" />
                        </Tooltip>
                    )}
                    <Tooltip title="Click to see details">
                        <Box
                            className={styles.leadName}
                            display="flex"
                            columnGap={1}
                            onClick={handleOpenDetails}
                        >
                            <Typography variant="body1" data-testid="lead-name">
                                {LeadUtils.getLeadName(lead)}
                            </Typography>

                            {lead.is_synthetic && (
                                <Typography variant="body1" color="textSecondary">
                                    (Testing Lead)
                                </Typography>
                            )}
                        </Box>
                    </Tooltip>
                </Flex>
            </TableCell>

            <TableCell className={styles.leadStage}>
                <SelectLeadStage
                    value={stage}
                    onChange={handleLeadStageChange}
                />
            </TableCell>

            <TableCell>
                <Typography>
                    {lead.ingestion_source ? normalizeSource(lead.ingestion_source) : 'Source not set'}
                </Typography>
            </TableCell>

            <TableCell sx={{ position: 'relative' }}>
                <EditableLeadLabels lead={lead} />
            </TableCell>

            <TableCell>
                <Typography data-testid="lead-property-name">{property?.name || 'Property not set'}</Typography>
            </TableCell>

            <TableCell>
                <FlexColumn rowGap={0}>
                    <CopyText component="span" tooltip="Copy email" text={email} data-testid="lead-email">
                        {email || 'Not Set'}
                    </CopyText>
                    <CopyText component="span" tooltip="Copy phone number" text={phone} whiteSpace="nowrap" data-testid="lead-phone-number">
                        {prettyifyPhoneNumber(phone)}
                    </CopyText>
                </FlexColumn>
            </TableCell>

            <TableCell padding="none">
                <Menu lead={lead} />
            </TableCell>
        </TableRow>
    );
}
