import { ELeadStage } from 'src/services/api';

export const leadStageColors: Record<ELeadStage, {
    backgroundColor: string;
    textColor: string;
}> = {
    [ELeadStage.NOT_SET]: {backgroundColor: 'lightgray', textColor: 'black'},
    [ELeadStage.NEW]: {backgroundColor: '#99D117', textColor: 'white'},
    [ELeadStage.CONTACTED]: {backgroundColor: '#8A65EE', textColor: 'white'},
    [ELeadStage.TOUR_REQUESTED]: {backgroundColor: '#0093D711', textColor: '#0093D7'},
    [ELeadStage.TOUR_SCHEDULED]: {backgroundColor: '#0093D744', textColor: '#0093D7'},
    [ELeadStage.TOUR_COMPLETED]: {backgroundColor: '#0093D7', textColor: 'white'},
    [ELeadStage.INVITED_TO_APPLY]: {backgroundColor: '#07BA8011', textColor: '#07BA80'},
    [ELeadStage.APPLICATION_COMPLETED]: {backgroundColor: '#07BA8044', textColor: '#07BA80'},
    [ELeadStage.APPLICATION_APPROVED]: {backgroundColor: '#07BA80', textColor: 'white'},
    [ELeadStage.NOT_QUALIFIED]: {backgroundColor: '#D50200', textColor: 'white'},
    [ELeadStage.NOT_INTERESTED]: {backgroundColor: '#D5020033', textColor: '#D50200'},
};