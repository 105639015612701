import React, { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';

import type { ILead, ELeadStage } from 'src/services/api';
import { LeadUtils } from 'src/utils';
import { useUpdateLeadStage } from 'src/services/api/leads';
import { Flex, FlexColumn } from 'src/components/flex';
import TableCell from 'src/components/table/TableCell';
import Menu from 'src/pages/landlord/leads/components/table/LeadsTableRowMenu';
import SelectLeadStage from 'src/components/input/select-lead-stage/SelectLeadStage';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import { normalizeSource } from 'src/pages/landlord/analytics/utils';

interface Props {
    lead: ILead,
}

export default function MobileLeadsTableRow({ lead }: Props) {
    const { properties } = useLeadsPageContext();

    const { mutate: updateLead } = useUpdateLeadStage(lead.uuid);

    const [stage, setStage] = useState<ELeadStage>(lead.stage);

    const property = useMemo(() => {
        return properties?.find((o) => o.id === lead.property_id);
    }, [properties, lead.property_id]);

    const handleLeadStageChange = (newStage: ELeadStage) => {
        setStage(newStage);
        updateLead(newStage);
    };

    return (
        <TableRow sx={{ borderBottom: '1px solid var(--border-color)' }}>
            <TableCell width="100%">
                <FlexColumn rowGap={0.5}>
                    <Flex width="100%" alignItems="center">
                        <Typography variant="body1">
                            {LeadUtils.getLeadName(lead)}
                        </Typography>
                    </Flex>

                    <Typography variant="body2">{property?.name || 'Property not set'}</Typography>

                    <Typography variant="body2">
                        {lead.ingestion_source ? normalizeSource(lead.ingestion_source) : 'Source not set'}
                    </Typography>

                    <SelectLeadStage
                        value={stage}
                        onChange={handleLeadStageChange}
                    />
                </FlexColumn>
            </TableCell>

            <TableCell width={40} padding="none">
                <Menu lead={lead} />
            </TableCell>
        </TableRow>
    );
}
