import { ELeadStage, LEAD_STAGES, SELECTABLE_LEAD_STAGES } from 'src/services/api';

const leadStageLabels: Record<typeof LEAD_STAGES[0], string> = {
    [ELeadStage.NOT_SET]: 'Not Set',
    [ELeadStage.NEW]: 'New',
    [ELeadStage.CONTACTED]: 'Contacted',
    [ELeadStage.TOUR_REQUESTED]: 'Tour Requested',
    [ELeadStage.TOUR_SCHEDULED]: 'Tour Scheduled',
    [ELeadStage.TOUR_COMPLETED]: 'Tour Completed',
    [ELeadStage.INVITED_TO_APPLY]: 'Invited to Apply',
    [ELeadStage.APPLICATION_COMPLETED]: 'Application Completed',
    [ELeadStage.APPLICATION_APPROVED]: 'Application Approved',
    [ELeadStage.NOT_INTERESTED]: 'Not Interested',
    [ELeadStage.NOT_QUALIFIED]: 'Not Qualified',
};

export const leadStageOptions = LEAD_STAGES.map((stage) => {
    return {
        value: stage,
        label: leadStageLabels[stage],
    };
});

export const selectableLeadStageOptions = SELECTABLE_LEAD_STAGES.map((stage) => {
    return {
        value: stage,
        label: leadStageLabels[stage],
    };
});
