import { createContext, useContext } from 'react';

import type { IConversation } from 'src/api/landlord-messages-api';
import type { ILead, Notification, TeamSettings } from 'src/services/api';
import type { IUser } from 'src/api/users-api';

type IAuthenticatedState = {
    /**
     * If true, archived leads and conversations will be fetched.
     * Warning: The response size can get very large.
     */
    showArchived: boolean;
    setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;

    loggedInUser: IUser;
    teamSettings: TeamSettings;

    leads: ILead[];
    leadsUpdatedAt: number;
    leadsByUuid: Map<string, ILead>;
    leadIngestionSources: string[];

    conversations: IConversation[];
    conversationsUpdatedAt: number;
    conversationsByLeadUuid: Map<string, IConversation>;

    notifications: Notification[];

    isPaywallEnabled: boolean;

    isInactivePropertiesModalOpen: boolean;
    setIsInactivePropertiesModalClosed: (value: boolean) => void;
};

// @ts-expect-error lazy init
export const AuthenticatedStateContext = createContext<IAuthenticatedState>();

export const useAuthenticatedState = () => {
    const context = useContext(AuthenticatedStateContext);

    if (!context) {
        throw new Error('useAuthenticatedState must be used within an AuthenticatedStateProvider');
    }
    return context;
};
