import { type ILead, type ELeadStage } from 'src/services/api';
import type { IConversation } from 'src/api/landlord-messages-api';
import type { IProperty } from 'src/services/api';
import type { IOtherUser, IUser } from 'src/api/users-api';

export type TConversationsFilter = {
    assigneeIds: string[];
    leadStages: ELeadStage[];
    leadLabelIds: string[];
    excludeLeadLabelIds: string[];
    propertyIds: string[];
    // When true, only conversations with no property will be shown.
    // We can overload the propertyId field to also represent the absence of a property.
    // But, this is more explicit and less error-prone.
    hasNoProperty: boolean;
    propertyLabelIds: string[];
};

export enum EConversationTab {
    ALL = 'All',
    NEW = 'New',
    CONTACTED = 'Contacted',
    TOUR = 'Tour',
    APPLICATION = 'Application',
    NOT_QUALIFIED = 'Not Qualified',
    NOT_INTERESTED = 'Not Interested',
}

export const conversationTabs = Object.values(EConversationTab);

export enum EConversationCategory {
    Unread = 'Unread',
    Active = 'Active',
    Ghosted = 'Ghosted',
    Stale = 'Stale',
    Archived = 'Archived',
}

export const conversationCategories = Object.values(EConversationCategory);

export const initialConversationsPagesByCategory = conversationCategories.reduce((acc, cat) => {
    return {
        ...acc,
        [cat]: 1
    };
}, {} as Context['pagesByCategory']);

export type TLandlordConversationsContext = {
    loggedInUser: IUser;
    teamMembers: IOtherUser[];
    leads: ILead[];
    leadsByUuid: Map<string, ILead>;
    properties: IProperty[];

    /**
     * All conversations, before filters are applied.
     */
    conversations: IConversation[];
    // refetchConversations: () => void;
    /**
     * Conversations after filters are applied.
     */
    filteredConversations: IConversation[];

    tab: EConversationTab;
    setTab: Action<Context['tab']>;
    conversationsByTabs: Record<EConversationTab, IConversation[]>;

    search: string;
    setSearch: Action<Context['search']>;
    isFilterOpen: boolean;
    setFilterOpen: Action<Context['isFilterOpen']>;
    filter: TConversationsFilter;
    setFilter: Action<Context['filter']>;
    resetFilter: () => void;
    isSearchOrFilterActive: boolean;

    rowsPerPage: number;

    categories: EConversationCategory[];
    pagesByCategory: Record<EConversationCategory, number>;
    setPagesByCategory: Action<Context['pagesByCategory']>;
    conversationsByCategory: Record<EConversationCategory, IConversation[]>;
    visibleConversationsByCategory: Record<EConversationCategory, IConversation[]>;
    visibleConversations: IConversation[];

    /**
     * Conversations that are currently selected.
     * User can select multiple conversations at a time.
     * Conversations can be selected by clicking on the checkbox next to them or by pressing the space key while the conversation is focused.
     */
    selectedConversations: IConversation[];
    setSelectedConversations: Action<Context['selectedConversations']>;
    /**
     * User can only have one focused conversation at a time.
     * A conversation can be focused with the up and down arrow keys.
     */
    focusedConversation?: IConversation;
    setFocusedConversation: Action<Context['focusedConversation']>;

    openConversation: (conversation: IConversation) => void;

    mobileMenuAnchorEl?: HTMLElement;
    setMobileMenuAnchorEl: Action<Context['mobileMenuAnchorEl']>;
};

// Helper types to make the code more concise.
// Must not be exported!
type Action<T> = React.Dispatch<React.SetStateAction<T>>;
type Context = TLandlordConversationsContext;
