export enum StringFormat {
  SnakeCase = 'snakeCase',
  SentenceCase = 'sentenceCase',
  TitleCase = 'titleCase',
}

type FromStringFormat = StringFormat.SnakeCase;
type ToStringFormat = StringFormat.SentenceCase | StringFormat.TitleCase;

export const formatString = (
    str: string,
    fromFormat: FromStringFormat,
    toFormat: ToStringFormat,
    fallback = '',
): string => {
    if (!str) {
        return fallback;
    }

    switch (fromFormat) {
        case StringFormat.SnakeCase:
            switch (toFormat) {
                case StringFormat.SentenceCase: {
                    const firstLetter = str.charAt(0).toUpperCase();
                    const rest = str.slice(1);
                    return `${firstLetter}${rest}`.split('_').join(' ');
                }
                case StringFormat.TitleCase: {
                    return str.split('_').map((word) => {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    }).join(' ');
                }
                default:
                    return str;
            }
        default:
            return str;
    }
};
