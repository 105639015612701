import { useMutation } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import { apiClient } from 'src/services/api/client';

type ISubscribeToPushNotificationsRequest = {
    endpoint: string;
    p256dh: string;
    auth: string;
}

export const useSubscribeToPushNotifications = () => {
    return useMutation<
        void,
        IStandardErrorResponse,
        ISubscribeToPushNotificationsRequest
    >(
        async (payload) => {
            await apiClient('api/landlord/push-notifications/subscribe', {
                method: 'post',
                json: payload
            });
        }
    );
};
