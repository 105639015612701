import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import type { IProperty, IMessage } from 'src/services/api';
import type { ILead } from 'src/services/api';
import { Flex } from 'src/components/flex';
import { useAuthenticatedState } from 'src/authenticated-state/context';

interface InviteToScheduleShowingButtonProps {
    lead: ILead;
    property: IProperty;
    messages: IMessage[];
    sendMessage: (message: string) => Promise<void>;
}

function InviteToScheduleShowingButton({
    lead,
    property,
    messages,
    sendMessage,
}: InviteToScheduleShowingButtonProps) {
    const { loggedInUser } = useAuthenticatedState();

    const showingSchedulingLink = property.showing_scheduling_link?.link;

    const handleInviteToScheduleShowing = () => {
        const firstName = ` ${lead.name?.split(' ')[0] || ''}`;
        const message = `Hey${firstName}, you can sign up for a tour for ${property.name} using this link: ${showingSchedulingLink}`;
        sendMessage(message);
    };

    const isInvitedToScheduleShowing = useMemo(() => {
        if (!messages || !showingSchedulingLink) {
            return false;
        }

        const lastMessage = messages.at(-1);
        return lastMessage?.contents.includes(showingSchedulingLink) || false;
    }, [messages, showingSchedulingLink]);

    let disabledReason = '';
    if (!property) {
        disabledReason = 'Lead has no associated property';
    } else if (!showingSchedulingLink) {
        disabledReason = 'Property has no tour scheduling link';
    } else if (isInvitedToScheduleShowing) {
        disabledReason = 'Lead has already been invited';
    } else if (lead.owner !== loggedInUser.id) {
        disabledReason = 'You are not the owner of this lead';
    }

    return (
        <Tooltip title={disabledReason}>
            <div>
                <Button
                    variant="contained"
                    onClick={handleInviteToScheduleShowing}
                    disabled={!!disabledReason}
                    fullWidth
                >
                    Invite to Tour
                </Button>
            </div>
        </Tooltip>

    );
}

interface InviteToApplyButtonProps {
    lead: ILead;
    messages: IMessage[];
    property: IProperty;
    sendMessage: (message: string) => Promise<void>;
}

function InviteToApplyButton({
    lead,
    messages,
    property,
    sendMessage,
}: InviteToApplyButtonProps) {
    const { loggedInUser } = useAuthenticatedState();

    const applicationLink = property.application_link?.link;

    const isInvitedToApply = useMemo(() => {
        if (!messages || !applicationLink) {
            return false;
        }

        const lastMessage = messages.at(-1);
        return lastMessage?.contents.includes(applicationLink) || false;
    }, [messages, applicationLink]);

    let disabledReason = '';
    if (isInvitedToApply) {
        disabledReason = 'Lead has already been invited';
    } else if (!property) {
        disabledReason = 'Lead has no associated property';
    } else if (!applicationLink) {
        disabledReason = 'Property has no application link';
    } else if (lead.owner !== loggedInUser.id) {
        disabledReason = 'You are not the owner of this lead';
    }

    const handleInviteToApply = () => {
        if (!applicationLink) {
            return;
        }

        sendMessage(`To apply, please fill out your rental application here: ${applicationLink}`);
    };

    return (
        <Tooltip title={disabledReason}>
            <div>
                <LoadingButton
                    variant="contained"
                    onClick={handleInviteToApply}
                    disabled={!!disabledReason}
                    fullWidth
                >
                    {isInvitedToApply ? 'Invite Sent' : 'Invite to Apply'}
                </LoadingButton>
            </div>
        </Tooltip>
    );
}

interface Props {
    lead: ILead;
    property: IProperty;
    messages: IMessage[];
    sendMessage: (message: string) => Promise<void>;
}

export default function LeadSidebarQuickActions({
    lead,
    property,
    messages,
    sendMessage,
}: Props) {
    return (
        <Flex width="100%" sx={{ '& > div': { width: '100%' } }}>
            <InviteToScheduleShowingButton
                lead={lead}
                property={property}
                messages={messages}
                sendMessage={sendMessage}
            />

            <InviteToApplyButton
                lead={lead}
                property={property}
                messages={messages}
                sendMessage={sendMessage}
            />
        </Flex>
    );
}
