import React, { useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import MarkReadIcon from '@mui/icons-material/MarkEmailReadOutlined';
import MarkUnreadIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import SendIcon from '@mui/icons-material/SendRounded';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveIcon from '@mui/icons-material/UnarchiveOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';
import PeopleIcon from '@mui/icons-material/PeopleOutlineRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';

import { Flex } from 'src/components/flex';
import {
    type ILead,
    useUpdateConversationsReadStatus,
    useUpdateLeadsStatuses
} from 'src/services/api';
import { useAuthenticatedState } from 'src/authenticated-state/context';
import { ReadStatus } from 'src/api/landlord-messages-api';
import { useLandlordLayoutContext } from 'src/components/layout/landlord/LandlordLayoutProvider';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';
import CreateOrUpdateLeadModal from 'src/components/create-or-update-lead-modal';
import BulkChangeLeadStageModal from 'src/components/bulk-change-lead-stage-modal';
import BulkChangeLeadOwnerModal from 'src/components/bulk-change-lead-owner-modal';
import BulkMessageLeadsModal from 'src/pages/landlord/conversations/components/modals/BulkMessageLeadsModal';

export default function ConversationsToolbar() {
    const { loggedInUser, leads } = useAuthenticatedState();

    const {
        mutateAsync: updateReadStatus,
        isLoading: isUpdatingReadStatus,
        variables: updateReadStatusVariables,
    } = useUpdateConversationsReadStatus();

    const {
        mutateAsync: updateLeadsStatuses,
        isLoading: isUpdatingLeadsStatuses,
    } = useUpdateLeadsStatuses();

    const { sidebarWidth } = useLandlordLayoutContext();

    const {
        leadsByUuid,
        selectedConversations,
        setSelectedConversations,
    } = useConversationsPageContext();

    const [isSendingMessage, setSendingMessage] = useState(false);
    const [isEditingLead, setEditingLead] = useState(false);
    const [isChangingOwner, setChangingOwner] = useState(false);
    const [isChangingStage, setChangingStage] = useState(false);

    const isAnyConversationRead = selectedConversations.some((
        conversation
    ) => conversation.num_unread_messages === 0);

    const isAnyConversationUnread = selectedConversations.some((
        conversation
    ) => conversation.num_unread_messages > 0);

    const areAllConversationsArchived = selectedConversations.every((
        conversation
    ) => leadsByUuid.get(conversation.lead_uuid)?.is_archived);
    const areAllConversationsUnarchived = selectedConversations.every((
        conversation
    ) => !leadsByUuid.get(conversation.lead_uuid)?.is_archived);

    const selectedConversationsLeads = useMemo(() => {
        const _selectedConversationsLeads: ILead[] = [];

        selectedConversations.forEach(selectedConversation => {
            const selectedConversationsLead = leads.find(lead => {
                return lead.uuid === selectedConversation.lead_uuid;
            });

            if (selectedConversationsLead) {
                _selectedConversationsLeads.push(selectedConversationsLead);
            }
        });

        return _selectedConversationsLeads;
    }, [selectedConversations, leads]);

    if (selectedConversations.length === 0) {
        return null;
    }

    const setReadStatus = async (status: ReadStatus) => {
        const leadUuids = selectedConversations.map((o) => o.lead_uuid);

        await updateReadStatus({
            leadUuids,
            status,
        });

        setSelectedConversations([]);
    };

    const updateArchivedStatus = async (isArchived: boolean) => {
        const _leads: ILead[] = [];
        selectedConversations.forEach((conversation) => {
            const lead = leadsByUuid.get(conversation.lead_uuid);
            if (lead) {
                _leads.push(lead);
            }
        });

        await updateLeadsStatuses(_leads.map((lead) => ({
            uuid: lead.uuid,
            is_archived: isArchived,
        })));

        setSelectedConversations([]);
    };

    const cancel = () => {
        setSelectedConversations([]);
    };

    const numberOfTeamMembers = loggedInUser?.leasing_team_members?.length || 0;
    const numberOfOtherTeamMembers = numberOfTeamMembers - 1;

    return (
        <Flex
            className="desktopOnly"
            position="fixed"
            bottom={16}
            left={sidebarWidth}
            width={`calc(100vw - ${sidebarWidth}px)`}
            justifyContent="center"
        >
            <Flex component={Paper} py={0.5} px={1} alignItems="center" bgcolor="common.white" border="1px solid var(--border-color)">
                {isAnyConversationUnread && (
                    <LoadingButton
                        startIcon={<MarkReadIcon />}
                        onClick={() => setReadStatus(ReadStatus.READ)}
                        loading={updateReadStatusVariables?.status === ReadStatus.READ && isUpdatingReadStatus}
                        data-testid="mark-read-button"
                    >
                        Mark Read
                    </LoadingButton>
                )}

                {isAnyConversationRead && (
                    <LoadingButton
                        startIcon={<MarkUnreadIcon />}
                        onClick={() => setReadStatus(ReadStatus.UNREAD)}
                        loading={updateReadStatusVariables?.status === ReadStatus.UNREAD && isUpdatingReadStatus}
                        data-testid="mark-unread-button"
                    >
                        Mark Unread
                    </LoadingButton>
                )}

                {selectedConversations.length > 1 && (
                    <Button
                        onClick={() => setSendingMessage(true)}
                        startIcon={<SendIcon />}
                        data-testid="bulk-message-button"
                    >
                        Bulk-message
                    </Button>
                )}
                <BulkMessageLeadsModal open={isSendingMessage} onClose={() => setSendingMessage(false)} />

                {selectedConversations.length > 0 && (
                    <Button
                        onClick={() => setChangingStage(true)}
                        startIcon={<LeaderboardOutlinedIcon />}
                        data-testid="change-stage-button"
                    >
                        Change Stage
                    </Button>
                )}
                {isChangingStage && (
                    <BulkChangeLeadStageModal
                        open={isChangingStage}
                        onClose={() => setChangingStage(false)}
                        onSuccess={() => setSelectedConversations([])}
                        leads={selectedConversationsLeads}
                    />
                )}

                {selectedConversations.length === 1 && (
                    <>
                        <Button
                            onClick={() => setEditingLead(true)}
                            startIcon={<PersonIcon />}
                            data-testid="edit-lead-button"
                        >
                            Edit Lead
                        </Button>

                        {isEditingLead && (
                            <CreateOrUpdateLeadModal
                                lead={leadsByUuid.get(selectedConversations[0].lead_uuid)}
                                onClose={() => setEditingLead(false)}
                                onSave={() => {
                                    setEditingLead(false);
                                    setSelectedConversations([]);
                                }}
                            />
                        )}
                    </>
                )}

                {numberOfOtherTeamMembers > 0 && (
                    <Button
                        onClick={() => setChangingOwner(true)}
                        startIcon={<PeopleIcon />}
                        data-testid="change-owner-button"
                    >
                        Change Owner
                    </Button>
                )}
                <BulkChangeLeadOwnerModal
                    open={isChangingOwner}
                    onClose={() => setChangingOwner(false)}
                    onSuccess={() => setSelectedConversations([])}
                    leads={selectedConversationsLeads}
                />

                {areAllConversationsUnarchived && (
                    <LoadingButton
                        onClick={() => updateArchivedStatus(true)}
                        startIcon={<ArchiveIcon />}
                        loading={isUpdatingLeadsStatuses}
                        data-testid="archive-conversation-button"
                    >
                        Archive
                    </LoadingButton>
                )}

                {areAllConversationsArchived && (
                    <LoadingButton
                        onClick={() => updateArchivedStatus(false)}
                        startIcon={<UnarchiveIcon />}
                        loading={isUpdatingLeadsStatuses}
                        data-testid="unarchive-conversation-button"
                    >
                        Unarchive
                    </LoadingButton>
                )}

                <Divider orientation="vertical" />

                <IconButton
                    onClick={cancel}
                    size="small"
                    sx={{ borderRadius: 1 }}
                    data-testid="cancel-button"
                >
                    <CloseIcon />
                </IconButton>
            </Flex>
        </Flex>
    );
}
